import React, { useEffect, useState } from 'react';
import Media from 'react-media';
import { Link, useHistory } from 'react-router-dom';
import Loading from '../components/loading';
import * as api from '../service/api'

function UserAccess() {
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const [acessos, setAcessos] = useState([])

    useEffect(() => {
        setTimeout(() => {
            api.acessos()
                .then(res => {
                    if (!res.success) {
                        if (res.code == 0) {
                            history.replace('/logout')
                        }
                        return
                    }
                    setLoading(false)
                    setAcessos(res.acessos)
                })
        }, 500);
    }, [])

    return (
        <div className="container mx-auto pt-10">
            <div className="flex justify-between items-center px-2">
                <span className="text-3xl">Atividade recente</span>
                <Link to="/app/account" className="text-blue-500 font-black">Voltar</Link>
            </div>

            <Media query="(max-width: 599px)" render={() => (
                <div className="p-5">
                    {loading ?
                        <Loading />
                        : acessos.length == 0
                            ? (<center className="mt-10"><span className="text-2xl text-gray-500">Nenhum dado foi encontrado...</span></center>)
                            : acessos.map((acesso, index) => (
                                <div key={index} className="flex flex-col bg-white text-black border border-gray-500 my-5 p-2">
                                    <span>Horário: <strong>{acesso.horario}</strong></span>
                                    <span>IP: <strong>{acesso.ip}</strong></span>
                                    <span className="mt-2">Dispositivo: <strong>{acesso.aparelho}</strong></span>
                                </div>
                            ))
                    }
                </div>
            )} />

            {/* acessos.map((acesso, index) => (
    <tr key={index} className="bg-white border border-gray-500">
        <td>
            <span className="text-center ml-2 font-semibold">{acesso.horario}</span>
        </td>
        <td className="px-16 py-2">
            <span>{acesso.ip}</span>
        </td>
        <td className="px-16 py-2">
            <span>{acesso.aparelho}</span>
        </td>
    </tr>
)) */}

            <Media query="(min-width: 600px)" render={() => (


                <div className="py-5">
                    {loading ?
                        <Loading />
                        :
                        acessos.length == 0
                            ? (<center className="mt-10"><span className="text-2xl text-gray-500">Nenhum dado foi encontrado...</span></center>)
                            :
                            <table className="min-w-full table-auto text-black">
                                <thead>
                                    <tr className="bg-gray-800">
                                        <th className="py-2">
                                            <span className="text-gray-300">Horário</span>
                                        </th>
                                        <th className="py-2">
                                            <span className="text-gray-300">IP</span>
                                        </th>
                                        <th className="py-2">
                                            <span className="text-gray-300">Aparelho</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-gray-200">
                                    {acessos.map((acesso, index) => (
                                        <tr key={index} className="bg-white border border-gray-500">
                                            <td>
                                                <span className="text-center ml-2 font-semibold">{acesso.horario}</span>
                                            </td>
                                            <td className="px-16 py-2">
                                                <span>{acesso.ip}</span>
                                            </td>
                                            <td className="px-16 py-2">
                                                <span>{acesso.aparelho}</span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                    }
                </div>
            )} />
        </div>
    )
}

export default UserAccess;