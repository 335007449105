import axios from "axios"
import qs from 'qs'
import * as auth from './auth'

// export const SOCKET_FLUSSONIC = 'http://localhost:9997'
// export const SOCKET_FLUSSONIC = 'http://tv-socket.jupiter.com.br:9997'
export const SOCKET_FLUSSONIC = 'https://tv-socket.jupiter.com.br:9998'

// const API = process.env.REACT_APP_API_URL
const API = process.env.NODE_ENV === 'production'
    ? "https://api.jupiter.com.br/action/GerenciaUsuarioTV"
    : "http://localhost/api/action/GerenciaUsuarioTV"

const instance = axios.create({
    baseURL: API,
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    withCredentials: true
})

instance.interceptors.request.use(
    config => {
        let authToken = auth.getToken()
        if (authToken) {
            config.headers.Authorization = `tokenAPI ${authToken}`;
        }
        return config;
    },
    err => Promise.reject(err)
);

export const autenticarUsuario = (dados) => {
    return instance.post('/autenticarUsuario', qs.stringify(dados))
        .then(res => res.data)
}

export const verificarCPF = (cpf) => {
    return instance.post('/verificarCPF', qs.stringify({ cpf }))
        .then(res => res.data)
}

export const enviarAcessoCliente = (contato) => {
    return instance.post('/enviarAcessoCliente', qs.stringify({ contato }))
        .then(res => res.data)
}

export const verificarUsuario = (usuario) => {
    return instance.post('/verificarUsuario', qs.stringify(usuario))
        .then(res => res.data)
}

export const verificarCodigo = (info) => {
    return instance.post('/verificarCodigoRedefinicao', qs.stringify(info))
        .then(res => res.data)
}

export const redefinirSenha = (info) => {
    return instance.post('/redefinirSenha', qs.stringify(info))
        .then(res => res.data)
}

export const alterarEmail = (dados) => {
    return instance.post('/alterarEmail', qs.stringify(dados))
        .then(res => res.data)
}

export const alterarSenha = (dados) => {
    return instance.post('/alterarSenha', qs.stringify(dados))
        .then(res => res.data)
}

export const deslogarTodos = () => {
    return instance.post('/deslogarTodos')
        .then(res => res.data)
}

export const infoUsuario = () => {
    return instance.post("/retornarDadosDoUsuario")
        .then(res => res.data)
}

export const dispositivos = () => {
    return instance.post("/retornarDispositivos")
        .then(res => res.data)
}

export const acessos = () => {
    return instance.post("/retornarAcessos")
        .then(res => res.data)
}

export const ativarDispositivo = (codigo) => {
    return instance.post("/ativarSTB", qs.stringify({ codigo_ativacao: codigo }))
        .then(res => res.data)
}

export const removerDispositivo = (id) => {
    return instance.post("/removerDispositivo", qs.stringify({ id_dispositivo: id }))
        .then(res => res.data)
}

export const retornarCanaisWEBAoVivo = () => {
    return instance.post("/retornarCanaisWEBAoVivo")
        .then(res => res.data)
}

export const retornarCanaisVOD = () => {
    return instance.post("/retornarCanaisVOD")
        .then(res => res.data)
}

export const retornarUltimosVideosDoCanalVOD = (conteudo) => {
    return instance.post("/retornarUltimosVideosDoCanalVOD", qs.stringify({ conteudo }))
        .then(res => res.data)
}

export const retornarVideosDoCanalVOD = (conteudo, pagina) => {
    return instance.post("/retornarVideosDoCanalVOD", qs.stringify({ conteudo, pagina }))
        .then(res => res.data)
}

export const conteudosComGeneros = () => {
    return instance.post("/conteudosComGeneros")
        .then(res => res.data)
}

export const retornarUltimosEventosDoCanal = (conteudo) => {
    return instance.post("/retornarUltimosEventosDoCanal", qs.stringify({ conteudo }))
        .then(res => res.data)
}

export const retornarEventoDoCanal = (epg_uuid) => {
    return instance.post("/retornarEventoDoCanal", qs.stringify({ epg_uuid }))
        .then(res => res.data)
}

export const pegarEPG = (date) => {
    return instance.post("/pegarEPG", qs.stringify({ date }))
        .then(res => res.data)
}

export const retonarConteudoPorUUID = (conteudo_uuid) => {
    return instance.post("/retonarConteudoPorUUID", qs.stringify({ conteudo_uuid }))
        .then(res => res.data)
}

export const retonarVideoPorUUID = (uuid) => {
    return instance.post("/retonarVideoPorUUID", qs.stringify({ uuid }))
        .then(res => res.data)
}

export const buscarTitulos = (busca) => {
    return instance.post("/buscarTitulos", qs.stringify({ busca }))
        .then(res => res.data)
}

export const retonarEventosPorCategoria = (categorias) => {
    return instance.post("/retonarEventosPorCategoria", qs.stringify({ categorias }))
        .then(res => res.data)
}