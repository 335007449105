import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Loading from '../components/loading';
import * as api from '../service/api'

function LogoutAll() {
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [feedback, setFeedback] = useState(null)

    const logoutAll = () => {
        setLoading(true)
        setTimeout(() => {
            api.deslogarTodos()
                .then(res => {
                    if (!res.success) {
                        if (res.code == 0) {
                            history.replace('/logout')
                        }
                        return
                    }
                    setFeedback(
                        <div className="w-1/4 bg-green-600 bg-opacity-50 rounded relative p-2">
                            <button className="absolute top-0 right-0 m-1" type="button" onClick={() => { setFeedback(null) }}>
                                <span>&times;</span>
                            </button>
                            <div>Os dispositivos serão deslogados</div>
                        </div>
                    )
                    setTimeout(() => {
                        history.replace('/app/device_manager')
                    }, 2500);
                })
        }, 500);
    }

    return (
        <div className="container mx-auto pt-10 px-2">
            <span className="text-3xl">Encerrar sessão em todos os aparelhos</span>
            {loading
                ?
                <Loading />
                :
                <>
                    <div className="mt-5 text-gray-400">
                        <p>Você tem certeza de que deseja encerrar a sessão da sua conta em todos os aparelhos?</p>
                        <p>Obs.: Pode ser que demore para deslogar todos os aparelhos</p>
                    </div>
                    <div className="mt-5">
                        <div className="activate-buttons col-lg-2" >
                            <button onClick={() => logoutAll()} className="bg-red-600 hover:bg-red-700 p-2 rounded mr-2">Deslogar todos</button>
                            <Link to="/app/account" className="bg-gray-600 hover:bg-gray-700 p-2 rounded ">Cancelar</Link>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default LogoutAll;