import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import io from 'socket.io-client';
import Loading from '../components/loading';
import * as api from '../service/api'


function Ativar() {
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [codigo, setCodigo] = useState("")
    const [erroCodigo, setErroCodigo] = useState(null)
    const [feedback, setFeedback] = useState(null)
    const [socket, setSocket] = useState(null)

    useEffect(() => {
        // let socket = io(api.SOCKET_FLUSSONIC)
        // setSocket(socket)
        // console.log(socket)

        return () => {
            // socket.disconnect()
        }
    }, [])

    const getFeedback = (tipo, msg) => {
        switch (tipo) {
            case 'success':
                return (
                    <div className="w-1/4 bg-green-500 bg-opacity-50 rounded relative p-2">
                        <button className="absolute top-0 right-0 m-1" type="button" onClick={() => { setFeedback(null) }}>
                            <span>&times;</span>
                        </button>
                        <div>{msg}</div>
                    </div>
                )
            case 'error':
                return (
                    <div className="w-1/4 bg-red-600 bg-opacity-50 rounded relative p-2">
                        <button className="absolute top-0 right-0 m-1" type="button" onClick={() => { setFeedback(null) }}>
                            <span>&times;</span>
                        </button>
                        <div>{msg}</div>
                    </div>
                )
        }
    }

    const ativarDispositivo = () => {
        if (codigo.length < 6) {
            setErroCodigo('Código deve ter 6 caracteres')
            return;
        }

        setLoading(true)
        setTimeout(() => {
            api.ativarDispositivo(codigo.toLowerCase())
                .then(res => {
                    setLoading(false)
                    if (!res.success) {
                        if (res.code == 0) {
                            history.replace('/logout')
                        } else {
                            setFeedback(getFeedback('error', res.message))
                        }
                        return
                    }
                    let socket = io(api.SOCKET_FLUSSONIC)
                    socket.on('connect', () => {
                        socket.emit('activation', res)
                        setFeedback(getFeedback('success', 'Dispositivo ativado'))
                        setCodigo('')
                        setTimeout(() => {
                            history.replace('/app/device_manager')
                            socket.disconnect()
                        }, 1500);
                    })

                })
        }, 500);
    }

    const onChange = (e) => {
        if (e.target.value.length >= 7) {
            e.preventDefault()
            return
        }
        setCodigo(e.target.value)
    }

    return (
        <div className="container mx-auto pt-5 px-5">
            <span className="text-3xl">Ativar seu dispositivo</span>
            {loading
                ? <Loading />
                :
                <>
                    {feedback}
                    <div className="mt-5">
                        <input required type="text" minLength='6' maxLength="6" value={codigo} className="p-2 rounded uppercase text-black" onChange={onChange} placeholder="código do dispositivo..." />
                        <div className="text-red-500">
                            {erroCodigo}
                        </div>
                    </div>
                    <div className="mt-2">
                        <button onClick={() => ativarDispositivo()} className="bg-blue-600 hover:bg-blue-700 p-2 rounded mr-2">Ativar</button>
                        <Link to="/app/account"><button type="button" className="bg-gray-600 hover:bg-gray-700 p-2 rounded">Cancelar</button></Link>
                    </div>

                    <ul className="mt-5 ml-5 list-circle font-light text-gray-500">
                        <li>Siga as instruções no seu aparelho para ativar seu dispositivo</li>
                        <li>Certifique-se que seu aparelho esteja conectado</li>
                        <li>Atenção o código gerado pelo dispositivo tem duração máxima de 10 minutos</li>
                        <li>Caso seu usuário tenha atingido a quantidade máxima permitida, você pode remover <div>algum de seus dispositivos cadastrados em  <Link className="text-blue-500" to="/app/device_manager">Meus dispositivos</Link></div></li>
                    </ul>
                </>
            }
        </div>
    )
}

export default Ativar;