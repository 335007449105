import React, { useRef, useState } from 'react';
import * as api from '../service/api'
import md5 from 'md5'
import * as auth from '../service/auth'
import { Link, useHistory } from 'react-router-dom';
const logoUrl = process.env.PUBLIC_URL + './imagens/logo.svg';

function Login() {
    const history = useHistory()
    const senhaRef = useRef()

    const [verSenha, setVerSenha] = useState(false)

    const [usuario, setUsuario] = useState('')
    const [senha, setSenha] = useState('')
    const [erros, setErros] = useState({ usuario: null, senha: null })
    const [returnError, setReturnError] = useState(null)

    useState(() => {
        if (auth.estaLogado())
            history.replace('/app')
    }, [])

    const showError = (msg) => {
        setReturnError(
            <div className="bg-red-100 border border-red-400 text-red-700 px-5 py-3 rounded relative mt-5" role="alert">
                <span onClick={() => { setReturnError(null) }} className="absolute top-0 bottom-0 right-0 m-2">
                    <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
                </span>
                <span className="block sm:inline">{msg}</span>
            </div>
        )
    }

    const autenticar = () => {

        if (usuario.length < 1) {
            setErros({ ...erros, usuario: "Campo obrigatório" })
            return
        }

        if (senha.length === 0) {
            setErros({ ...erros, senha: "O campo de senha é obrigatório" })
            return
        }

        if (senha.length < 5) {
            setErros({ ...erros, senha: "O campo de senha dever ter no mínimo 5 caracteres" })
            return
        }

        setErros({ usuario: null, senha: null })

        api.autenticarUsuario({ usuario: usuario, senha: md5(senha) })
            .then(res => {
                if (!res.success) {
                    showError(res.message)
                    return
                }

                auth.setToken(res.token)
                history.replace('/app')
            })
    }

    const handleKeypress = (e) => {
        if (e.key === 'Enter')
            autenticar()
    }

    const alternarVisibilidadeDoCampoSenha = () => {
        setVerSenha(!verSenha)
        senhaRef.current.focus()
    }

    return (
        <div className="pt-24 px-5">
            <div className="rounded mx-auto py-10 flex flex-col w-3/4 lg:w-1/4 items-center">
                <img className="w-48" src={logoUrl} alt='navbar-logo' />

                {returnError}

                <input autoFocus className="p-2 rounded mt-5 text-black w-full" value={usuario} onChange={e => setUsuario(e.target.value)} type="text" placeholder="Usuário" />
                {erros.usuario && <p className="text-red-600 self-start py-1">{erros.usuario}</p>}


                <div className="flex flex-col w-full">
                    <div className='flex items-center relative w-full mt-3'>
                        <input ref={senhaRef} className="p-2 rounded text-black w-full" onKeyPress={handleKeypress} value={senha} onChange={e => setSenha(e.target.value)} type={verSenha ? 'text' : 'password'} placeholder="Senha" />
                        <i onClick={() => alternarVisibilidadeDoCampoSenha()} className={`absolute right-2 text-lg cursor-pointer text-gray-600 ${verSenha ? 'fa fa-eye-slash' : 'fa fa-eye'} `}></i>
                    </div>

                    {erros.senha && <p className="text-red-600 self-start py-1">{erros.senha}</p>}
                </div>


                <button onClick={() => autenticar()} className="mt-5 w-full bg-orange-600 hover:bg-orange-700 p-2 rounded font-medium text-white">ENTRAR</button>

                <Link className="self-end mt-5 text-blue-500 hover:text-blue-600 font-semibold tracking-wide" to="/recover_pass">Esqueci minha senha</Link>

                <span className="mt-10 font-semibold text-lg tracking-wide text-center w-full">Cliente Júpiter, solicite sua senha para acesso aos canais abertos da Júpiter TV clicando <Link className=" text-blue-500 hover:text-blue-600" to="/registerFree">aqui</Link></span>
            </div>
        </div >
    )
}

export default Login;