import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as api from '../service/api'

function Programacoes({ id, canalAtual, close }) {
    const [eventos, setEventos] = useState([])

    useEffect(() => {
        api.retornarUltimosEventosDoCanal(canalAtual?.conteudo_uuid)
            .then(res => {
                if (res.success) {
                    setEventos(res.eventos)
                    let element = document.querySelector(`#programacoes-${id}`);
                    element.scrollIntoView({ behavior: 'smooth', block: 'end' });
                }
            })
    }, [canalAtual])

    return <div id={`programacoes-${id}`} className="bg-gray-900 bg-opacity-75 flex flex-col px-5">
        {/* <img className="w-32" src={canalAtual?.icone} /> */}
        <div className="flex items-center justify-between">
            <span className="text-2xl font-bold py-2">Últimos programas do canal {canalAtual?.titulo}</span>
            <button onClick={() => { close() }} className="text-2xl m-4"><i className="fa fa-close"></i></button>
        </div>
        <div className="w-full flex flex-col lg:flex-row">
            {eventos.map(evento => (
                <div key={evento.fim} className="w-full flex flex-col p-2 justify-start">
                    <img style={{ objectFit: 'contain' }} onError={e => { e.target.error = null; e.target.src = canalAtual?.icone }} src={evento?.imagem} />
                    {evento.current && <span className="bg-transparent self-start text-red-400 font-semibold px-2 border border-red-500 rounded py-1 mt-2">Ao vivo</span>}
                    <Link target="_blank" to={`/app/detalhe/${evento.epg_uuid}`} className="text-lg mt-2">{evento.titulo}</Link>
                    <div className="flex items-center space-x-2">
                        <span className="text-sm">{evento.inicio_formatado} - {evento.fim_formatado}</span>
                        <img className="w-5" src={evento.classificacao} />
                    </div>
                    <span className="text-sm">{evento.subtitulo}</span>
                    <div className="mt-2 mr-5 h-20 scrollable">
                        <span className="font-light text-sm">{evento.descricao}</span>
                    </div>
                </div>
            ))}
        </div>
    </div >
}

export default Programacoes;