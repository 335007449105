import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import App from './App';
import Logout from './components/logout';
import { estaLogado } from './service/auth';
import Login from './views/Login';
import RecoverPass from './views/RecoverPass/RecoverPass';
import RegisterFreeAccount from './views/RegisterFreeAccount';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    estaLogado()
      ? <Component {...props} />
      : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
  )} />
)

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact={true} component={Login} />
        <Route path="/logout" component={Logout} />
        <Route path="/recover_pass" component={RecoverPass} />
        <Route path="/registerFree" component={RegisterFreeAccount} />
        <PrivateRoute path="/app" component={App} />
        <Route path="*" component={() => <Redirect to='/' />} />
        {estaLogado() && <Redirect to='/app' />}
      </Switch>
    </BrowserRouter>
  )
}

export default Routes;