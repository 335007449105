
export const estaLogado = () => localStorage.getItem('tv-jupiter-token') != null

export const getToken = () => localStorage.getItem('tv-jupiter-token')

export const setToken = (token) => {
    localStorage.setItem('tv-jupiter-token', token)
}

export const logout = () => {
    localStorage.clear()
}