import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Carousel from '../components/carousel/carousel';
import * as api from '../service/api'

function DetalheEvento() {
    const history = useHistory()
    let { epg_uuid } = useParams()
    let carregarCanais = true
    const [navItem, setNavItem] = useState('resumo')
    const [canal, setCanal] = useState(null)
    const [maisTitulos, setMaisTitulos] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        api.retornarEventoDoCanal(epg_uuid)
            .then(res => {
                if (res.success) {
                    if (res.evento != '') {
                        setCanal(res.canal)
                        setTimeout(() => {
                            let element = document.querySelector("#detalhe-evento");
                            element.scrollIntoView({ behavior: 'smooth', block: 'end' });
                        }, 300);

                        if (carregarCanais) {
                            api.retonarEventosPorCategoria(res.canal.evento.categorias)
                                .then(response => {
                                    setMaisTitulos(response.eventos)
                                    setLoading(false)
                                    carregarCanais = false
                                })
                        }
                    }
                }
            })
    }, [epg_uuid])

    const changeEvent = (canal, genero) => {
        // setCanalAtual(canal)
        // setMostrarEventosGenero(genero)
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
        <div>
            <div id="detalhe-evento" className="flex flex-col justify-between">
                <div className="image-container" >
                    <img onError={e => { e.target.error = null; e.target.src = canal.icone }} src={canal?.evento?.imagem} />
                </div>
                <div className="absolute flex flex-col px-5 lg:py-10 w-full lg:w-1/2">
                    <div className="self-center py-2">
                        <div>
                            <span onClick={() => setNavItem('resumo')} className={`cursor-pointer hover:text-orange text-sm p-2 ${navItem == 'resumo' ? "border-b-2 border-orange-500" : ''}`}>Resumo</span>
                            <span onClick={() => setNavItem('detalhes')} className={`cursor-pointer hover:text-orange text-sm p-2 ${navItem == 'detalhes' ? "border-b-2 border-orange-500" : ''}`}>Detalhes</span>
                        </div>
                    </div>
                    {navItem === 'resumo' ? (
                        <div className="flex flex-col pt-5">
                            <span className="text-lg">{canal?.titulo}</span>
                            <span className="text-3xl mt-2">{canal?.evento?.titulo}</span>
                            <div className="flex items-center space-x-2">
                                <span className="text-sm">{canal?.evento?.dia}</span>
                                <span className="text-sm">{canal?.evento?.inicio_formatado} - {canal?.evento?.fim_formatado}</span>
                                <img className="w-5" src={canal?.evento?.classificacao} />
                            </div>
                            <span className="py-1">Categorias: {canal?.evento?.categorias}</span>
                            <span className="text-lg">{canal?.evento?.subtitulo}</span>
                            <div className="mt-2 mr-5 xl:h-full">
                                <span className="font-light text-lg">{canal?.evento?.descricao}</span>
                            </div>
                            <div className="mt-2 py-2">
                                {/* <button className="bg-gray-600 hover:bg-white hover:text-black p-2 rounded font-semibold"><i className="fa fa-play-circle mr-1" />Assistir agora</button> */}
                            </div>
                        </div>
                    ) : (
                            <div className="flex justify-between mr-20 pt-5">
                                <div className="flex flex-col lg:space-y-5">
                                    <span className="text-lg">{canal?.titulo}</span>
                                    <div className="flex flex-col">
                                        <span>Categorias</span>
                                        <span className="text-lg">{canal?.evento?.categorias}</span>
                                    </div>
                                    <div className="flex flex-col">
                                        <span>Classificação indicativa</span>
                                        <img className="w-5" src={canal?.evento?.classificacao} />
                                    </div>
                                </div>
                                <div>
                                    <span className="px-5">Onde vai passar</span>
                                    <div className="flex flex-col lg:space-y-5 h-64 scrollable mt-2 px-5">
                                        {canal?.proximos_eventos?.map(proximoEvento => {
                                            let date = new Date(proximoEvento.inicio)
                                            let options = { hour: 'numeric', minute: 'numeric', weekday: 'long', month: 'numeric', day: 'numeric' };
                                            date = date.toLocaleString('pt-BR', options)
                                            return (
                                                <div key={proximoEvento.epg_uuid} className="bg-gray-100 bg-opacity-25 rounded p-2">
                                                    <img width="100px" src={canal.icone} alt="imagem canal" />
                                                    <span>{capitalizeFirstLetter(date)}</span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        )}
                </div>
            </div>

            {!loading &&
                <>
                    <div className="flex flex-row items-center lg:justify-start pl-10 mt-5">
                        <span className="text-2xl font-light">Outros títulos nesta categoria</span>
                    </div>

                    <div className="flex flex-row flex-wrap items-center justify-center">
                        {maisTitulos?.map((evento, index) => (
                            <div onClick={() => { history.push(`/app/detalhe/${evento.epg_uuid}`) }} key={index} className="rounded cursor-pointer relative w-1/5 m-2">
                                <img className="bg-cover" onError={(e) => { e.target.src = evento.imagem_canal }} src={evento?.imagem} alt={evento.titulo} />
                                <div className="absolute bottom-0 w-full flex flex-row justify-between p-2 bg-gray-800 bg-opacity-50">
                                    <div className="w-2/3 flex flex-row justify-start items-center">
                                        <span className="truncate text-lg font-semibold">{evento?.titulo}</span>
                                        <img className="w-5 ml-1" src={evento?.classificacao} />
                                    </div>
                                    <img className="w-1/3 self-end" src={evento.imagem_canal} alt={evento.canal} />
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            }
        </div>
    )
}

export default DetalheEvento