import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ProgramacoesVOD from '../components/ProgramacoesVOD';
import Carousel from '../components/carousel/carousel'
import * as api from '../service/api'

function CanaisVOD() {
    const [canais, setCanais] = useState([])
    const [canal, setCanal] = useState(null)

    useEffect(() => {
        api.retornarCanaisVOD()
            .then(res => {
                if (res.success) {
                    setCanais(res.canais)
                    // setLoading(false)
                }
            })
    }, [])

    return (
        <div className="flex flex-col">
            <div className="flex flex-row items-center lg:justify-start pl-10 mt-10">
                <span className="text-2xl font-light">Transmissões locais</span>
            </div>
            <Carousel>
                {canais.map((canal, index) => {
                    return (
                        <div onClick={() => { setCanal(canal) }} key={index} className="slide rounded cursor-pointer absolute">
                            <img className="bg-cover" src={canal.icone} alt={canal.titulo} />
                            <div className="absolute bottom-0 w-full flex flex-row justify-between p-2 bg-gray-800 bg-opacity-50">
                                <div className="w-2/3 flex flex-row justify-start items-center">
                                    <span className="truncate text-lg font-semibold">{canal?.titulo}</span>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </Carousel>
            {canal && <ProgramacoesVOD canal={canal} close={() => setCanal(null)} />}

        </div>
    )
}

export default CanaisVOD;