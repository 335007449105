/**
 * Created by satadru on 3/30/17.
 */
import EPGChannel from '../models/EPGChannel'
import EPGEvent from '../models/EPGEvent'


// import MockDataService from '../utils/MockDataService'

export default class EPGData {

    constructor(channels) {
        this.channels = channels
    }

    getChannel(position) {
        return this.channels[position]
    }

    getEvents(channelPosition) {
        let events = this.channels[channelPosition].eventos
        return events || []
    }

    getEventCount(channelPosition) {
        return this.getEvents(channelPosition).length
    }

    getEvent(channelPosition, programPosition) {
        let channel = this.channels[channelPosition]
        let events = channel.eventos
        return events[programPosition]
    }

    getEventPosition(channelPosition, event) {
        let events = this.channels[channelPosition].eventos
        for (let i = 0; i < events.length; i++) {
            if (this.isEventSame(event, events[i])) {
                return i
            }
        }
    }

    getChannelCount() {
        return this.channels.length
    }

    isEventSame(event1, event2) {
        if (event1.inicio == event2.inicio && event1.fim == event2.fim) {
            return true
        }
        return false
    }

    getChannelPosition(conteudo) {
        return this.channels.findIndex((el) => el.conteudo_uuid == conteudo.conteudo_uuid)
    }

    static isEventCurrent(event) {
        let now = Date.now()
        return now >= event.inicio && now <= event.fim
    }

    static isPast(event) {
        let now = Date.now()
        return event.fim < now
    }

    static isFuture(event) {
        let now = Date.now()
        return event.inicio > now
    }

    hasData() {
        return this.getChannelCount() > 0
    }
}