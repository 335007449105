import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CodigoDeRedefinicao from './CodigoDeRedefinicao';
import FormUsuario from './FormUsuario';

function RecoverPass() {

    return (
        <Switch>
            <Route path="/recover_pass" exact component={FormUsuario} />
            <Route path="/recover_pass/code" component={CodigoDeRedefinicao} />
        </Switch>
    )
}

export default RecoverPass;