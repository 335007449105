import React, { useEffect, useState } from "react"
import $ from 'jquery'
import './styles.scss'

function Carousel({ id, children }) {

    const scaling = 1.2
    let currentSliderCount = 0
    let showCount = 4
    const controlsWidth = 40

    useEffect(() => {

        let videoCount = $(`#slider-container-${id}`).children().length
        let sliderCount = Math.floor(videoCount / showCount)
        let win = $(window)
        let sliderFrame = $(".slider-frame")
        let sliderContainer = $(`#slider-container-${id}`)
        let slide = $(".slide")

        let scrollWidth = 0

        let windowWidth = win.width() - 5
        let frameWidth = windowWidth - (controlsWidth * 2)
        if (windowWidth >= 0 && windowWidth <= 414) {
            showCount = 1
        } else if (windowWidth >= 414 && windowWidth <= 768) {
            showCount = 3
        } else if (windowWidth >= 768 && windowWidth < 1920) {
            showCount = 4
        } else {
            showCount = 6
        }
        let videoWidth = (((windowWidth - (controlsWidth * 2)) - (5 * showCount)) / showCount)
        let videoHeight = Math.round(videoWidth / (16 / 9))

        let videoWidthDiff = (videoWidth * scaling) - videoWidth
        let videoHeightDiff = (videoHeight * scaling) - videoHeight

        sliderFrame.width(windowWidth)
        sliderFrame.height(videoHeight * scaling)

        sliderContainer.height(videoHeight * scaling)
        sliderContainer.width(((videoWidth + 5) * videoCount) + videoWidthDiff)
        sliderContainer.css("top", (videoHeightDiff / 2))
        sliderContainer.css("margin-left", (controlsWidth))

        slide.height(videoHeight)
        slide.width(videoWidth)

        $(".slide").mouseover(function () {
            $(this).css("width", videoWidth * scaling)
            $(this).css("height", videoHeight * scaling)
            $(this).css("top", -(videoHeightDiff / 2))
            // if ($(".slide").index($(this)) == 0 || ($(".slide").index($(this))) % 4 == 0) {
            //     // do nothing
            // }
            // else if (($(".slide").index($(this)) + 1) % 4 == 0 && $(".slide").index($(this)) != 0) {
            //     $(this).parent().css("margin-left", -(videoWidthDiff - controlsWidth))
            // }
            // else {
            //     $(this).parent().css("margin-left", - (videoWidthDiff / 2))
            // }
        }).mouseout(function () {
            $(this).css("width", videoWidth * 1)
            $(this).css("height", videoHeight * 1)
            $(this).css("top", 0)
            // $(this).parent().css("margin-left", controlsWidth)
        })

        let prev = $(`#prev-${id}`);
        let next = $(`#next-${id}`);

        const nextOnClick = () => {
            if (scrollWidth <= -((sliderCount * frameWidth))) {
                scrollWidth = 0
                currentSliderCount = -1
            } else
                scrollWidth = scrollWidth - frameWidth;
            sliderContainer.animate({
                left: scrollWidth
            }, 150, function () {
                currentSliderCount++
            });
        }

        const prevOnClick = () => {
            if (scrollWidth >= 0) {
                scrollWidth = -(frameWidth * sliderCount)
                currentSliderCount = sliderCount
            } else {
                scrollWidth = scrollWidth + frameWidth
            }
            sliderContainer.animate({
                left: scrollWidth
            }, 150, function () {
                currentSliderCount--
                // scrollWidth *= -1
            })
        }

        next.on("click", nextOnClick);
        prev.on("click", prevOnClick)

        return () => {
            next.off('click', nextOnClick)
            prev.off("click", prevOnClick)
        }
    }, [])

    return (
        <div className="slider-frame">
            <div id={`prev-${id}`} className="btn prev flex flex-row justify-center items-center rounded cursor-pointer"><i className="fa fa-chevron-left fa-2x" /></div>
            <div id={`next-${id}`} className="btn next flex flex-row justify-center items-center rounded cursor-pointer"><i className="fa fa-chevron-right fa-2x" /></div>
            <div id={`slider-container-${id}`} className="slider-container">
                {children}
            </div>
        </div>
    )
}

export default Carousel