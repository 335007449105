import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as api from '../service/api';
import * as auth from '../service/auth';
import { splitArray } from '../service/utils';

function Watch() {

    const history = useHistory()
    const { conteudo_uuid } = useParams()
    const [canal, setCanal] = useState(null)
    const [canais, setCanais] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        api.retonarConteudoPorUUID(conteudo_uuid)
            .then(res => {
                if (res.success) {
                    setCanal(res.conteudo)
                    setLoading(false)
                }
            })

    }, [conteudo_uuid])

    useEffect(() => {
        api.retornarCanaisWEBAoVivo()
            .then(res => {
                if (res.success) {
                    setCanais(splitArray(res.canais, 4))
                }
            })
    }, [])

    function mudarCanal(canal) {
        history.push(`/app/watch/${canal.conteudo_uuid}`)
        setTimeout(() => {
            let element = document.querySelector('#iframe');
            element.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }, 500);
    }

    return (
        <div className="flex flex-col">
            <div id="iframe" className="w-full" >
                <iframe className="w-full" style={{ height: '25rem' }} allowFullScreen src={`${canal?.link}?token=${auth.getToken()}`}></iframe>
                {/* <img onError={e => { e.target.error = null; e.target.src = canal?.icone }} src={canal?.evento?.imagem} /> */}
            </div>
            <div className="flex flex-row">
                <div className=" flex flex-col pt-5 px-10 lg:w-2/5">
                    {loading
                        ?
                        <div className="w-full mx-auto pt-12">
                            <div className="animate-pulse flex space-x-4">
                                <div className="flex-1 space-y-4 py-1">
                                    <div className="h-4 bg-gray-400 bg-opacity-25 rounded w-1/6"></div>
                                    <div className="h-4 bg-gray-400 bg-opacity-25 rounded w-1/5"></div>
                                    <div className="space-y-2">
                                        <div className="h-4 bg-gray-400 bg-opacity-25 rounded w-3/5"></div>
                                        <div className="h-4 bg-gray-400 bg-opacity-25 rounded w-3/4"></div>
                                        <div className="h-4 bg-gray-400 bg-opacity-25 rounded w-2/5"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <>
                            <span className="text-lg">{canal?.titulo}</span>
                            <span className="text-3xl mt-2">{canal?.evento?.titulo}</span>
                            <div className="flex items-center space-x-2">
                                <span className="text-sm">{canal?.evento?.inicio_formatado} - {canal?.evento?.fim_formatado}</span>
                                <img className="w-5" src={canal?.evento?.classificacao} />
                            </div>
                            <span className="py-1">Categorias: {canal?.evento?.categorias}</span>
                            <span className="text-lg">{canal?.evento?.subtitulo}</span>
                            <div className="mt-2 mr-5 h-20 xl:h-full ">
                                <span className="font-light text-lg">{canal?.evento?.descricao}</span>
                            </div>
                        </>
                    }
                </div>
                <div className="flex flex-col w-3/5 p-5">
                    <span>Mais canais</span>
                    {canais.length > 0 &&
                        canais.map((group, gIndex) => (
                            <div key={`group-${gIndex}`} className="flex flex-row">
                                {group.map((canal, index) => (
                                    <div onClick={() => { mudarCanal(canal) }} id="epg_image_container" key={`item-${index}`} className="m-1 item">
                                        <div className="bg-gray-800 rounded cursor-pointer">
                                            <img id="channel_icon" src={canal.icone} alt={canal.titulo} />
                                            <div id="epg_image" className="absolute top-0 w-full h-full">
                                                <div className="absolute w-full h-full flex flex-col justify-center p-2 bg-gray-700 bg-opacity-25">
                                                    <i className="fa fa-play-circle text-3xl self-center"></i>
                                                    <div className="flex">
                                                        <span className="truncate text-sm ">{canal?.evento?.titulo}</span>
                                                        <img className="w-3 ml-1" src={canal?.evento?.classificacao} />
                                                    </div>
                                                </div>
                                                <img onError={e => { e.target.error = null; e.target.style.display = 'none' }} src={canal?.evento?.imagem} alt={canal.evento.titulo} />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default Watch;