import md5 from 'md5'
import React, { useState, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import * as api from '../service/api'
import Loading from '../components/loading'

function Pass() {

    const history = useHistory()

    const senhaRef = useRef()
    const novaSenhaRef = useRef()
    const confirmacaoSenhaRef = useRef()

    const [loading, setLoading] = useState(false)

    const [verSenha, setVerSenha] = useState(false)
    const [verNovaSenha, setVerNovaSenha] = useState(false)
    const [verConfirmacaoSenha, setVerConfirmacaoSenha] = useState(false)

    const [senha, setSenha] = useState("")
    const [novaSenha, setNovaSenha] = useState("")
    const [confirmacaoSenha, setConfirmacaoSenha] = useState("")

    const [erro, setErro] = useState(null)

    const [erroSenha, setErroSenha] = useState(null)
    const [erroNovaSenha, setErroNovaSenha] = useState(null)
    const [erroConfirmacao, setErroConfirmacao] = useState(null)

    const alternarVisibilidadeDaSenha = () => {
        setVerSenha(!verSenha)
        senhaRef.current.focus()
    }

    const alternarVisibilidadeDaNovaSenha = () => {
        setVerNovaSenha(!verNovaSenha)
        novaSenhaRef.current.focus()
    }

    const alternarVisibilidadeDaConfirmacaoDeSenha = () => {
        setVerConfirmacaoSenha(!verConfirmacaoSenha)
        confirmacaoSenhaRef.current.focus()
    }

    const camposEstaoValidos = () => {
        return senha.length > 0 && novaSenha.length > 0 && confirmacaoSenha.length > 0 && novaSenha === confirmacaoSenha
    }

    const alterarSenha = () => {

        if (senha.length < 5) {
            setErroSenha('Sua senha deve ter no mínimo 5 caracteres')
            return
        }

        if (novaSenha.length < 5) {
            setErroNovaSenha('Sua senha deve ter no mínimo 5 caracteres')
            return
        }

        if (confirmacaoSenha.length < 5) {
            setErroConfirmacao('Sua senha deve ter no mínimo 5 caracteres')
            return
        }

        if (novaSenha !== confirmacaoSenha) {
            setErro('A confirmação de senha deve ser igual a sua nova senha')
            return
        }

        setLoading(true)
        api.alterarSenha({ novaSenha: novaSenha, senha: md5(senha) })
            .then(res => {
                if (!res.success) {
                    if (res.code == 0) {
                        history.replace('/logout')
                    } else {
                        setLoading(false)
                        setErro(res.message)
                    }
                    return
                }

                history.replace('/app/account')
            })
    }

    return (
        <div className="container mx-auto pt-10 px-5 lg:w-1/4">
            <span className="text-3xl">Alterar senha</span>
            {loading ? <Loading />
                :
                <div className="mt-2 flex flex-col">

                    {erro &&
                        <div className=" bg-red-600 bg-opacity-50 rounded relative p-2">
                            <button className="absolute top-0 right-0 m-1" type="button" onClick={() => { setErro(null) }}>
                                <span>&times;</span>
                            </button>
                            <div>{erro}</div>
                        </div>
                    }

                    <div className="flex flex-col mt-5" >
                        <label className="text-xl text-gray-400">Senha atual</label>
                        <div className='flex items-center relative w-full'>
                            <input ref={senhaRef} autoComplete='off' autoFocus className="w-full p-2 rounded text-black" required minLength="6" type={verSenha ? "text" : 'password'} name="senha" value={senha} onChange={e => setSenha(e.target.value)} />
                            <i onClick={() => alternarVisibilidadeDaSenha()} className={`absolute right-2 text-lg cursor-pointer text-gray-600 ${verSenha ? 'fa fa-eye-slash' : 'fa fa-eye'} `}></i>
                        </div>
                        <span className="text-red-600"> {erroSenha} </span>
                    </div>

                    <div className="flex flex-col mt-5" >
                        <label className="text-xl text-gray-400">Nova senha</label>
                        <div className='flex items-center relative w-full'>
                            <input ref={novaSenhaRef} autoComplete='off' className="p-2 rounded text-black w-full" required minLength="6" type={verNovaSenha ? "text" : 'password'} name="novaSenha" value={novaSenha} onChange={e => setNovaSenha(e.target.value)} />
                            <i onClick={() => alternarVisibilidadeDaNovaSenha()} className={`absolute right-2 text-lg cursor-pointer text-gray-600 ${verNovaSenha ? 'fa fa-eye-slash' : 'fa fa-eye'} `}></i>
                        </div>
                        <span className="text-red-600"> {erroNovaSenha} </span>
                    </div>

                    <div className="flex flex-col mt-5" >
                        <label className="text-xl text-gray-400">Confirmação da senha</label>

                        <div className='flex items-center relative w-full'>
                            <input ref={confirmacaoSenhaRef} autoComplete='off' className="p-2 rounded text-black w-full" required minLength="6" type={verConfirmacaoSenha ? "text" : 'password'} name="confirmacao" value={confirmacaoSenha} onChange={e => setConfirmacaoSenha(e.target.value)} />
                            <i onClick={() => alternarVisibilidadeDaConfirmacaoDeSenha()} className={`absolute right-2 text-lg cursor-pointer text-gray-600 ${verConfirmacaoSenha ? 'fa fa-eye-slash' : 'fa fa-eye'} `}></i>
                        </div>

                        <span className="text-red-600"> {erroConfirmacao} </span>
                    </div>

                    <div className="w-full mt-6 flex items-center justify-between">
                        <Link to="/app/account" className="bg-gray-600 hover:bg-gray-700 p-2 rounded ">Cancelar</Link>
                        <button disabled={!camposEstaoValidos()} onClick={() => alterarSenha()} className="bg-blue-600 hover:bg-blue-700 disabled:bg-opacity-80 disabled:bg-blue-300 p-2 rounded">Salvar</button>
                    </div>

                </div>
            }
        </div >
    )
}

export default Pass