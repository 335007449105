import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom'
import * as api from '../service/api'
function WatchVOD() {

    const history = useHistory()
    const { uuid } = useParams()
    const [video, setVideo] = useState(null)
    const [videos, setVideos] = useState([])
    const [loading, setLoading] = useState(true)
    const [pagina, setPagina] = useState(-1)
    const [uuidConteudo, setUUIDConteudo] = useState(null)

    const loader = useRef(null)

    const handleObserver = useCallback((entries) => {
        const target = entries[0]
        if (target.isIntersecting) {
            setPagina((prev) => prev + 1)
        }
    }, [])

    useEffect(() => {
        const option = {
            root: null,
            rootMargin: "20px",
            threshold: 0
        }
        const observer = new IntersectionObserver(handleObserver, option)
        if (loader.current)
            observer.observe(loader.current)
    }, [handleObserver])

    useEffect(() => {
        if (uuid) {
            api.retonarVideoPorUUID(uuid)
                .then(res => {
                    if (res.success) {
                        setVideo(res.video)
                        setUUIDConteudo(res.video.conteudo_uuid)
                        setLoading(false)
                    }
                })
        }

    }, [uuid])

    useEffect(() => {
        if (uuidConteudo) {
            api.retornarVideosDoCanalVOD(uuidConteudo, pagina)
                .then(res => {
                    if (res.success) {
                        setVideos((prev) => [...prev, ...res.videos])
                    }
                })
        }
    }, [uuidConteudo, pagina])

    function mudarVideo(video) {
        history.push(`/app/watch-vod/${video.uuid}`)
        setTimeout(() => {
            let element = document.querySelector('#iframe');
            element.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }, 500);
    }

    return (
        <div className="max-w-7xl mx-auto flex flex-col pb-5">
            <div id="iframe" className="w-full" >
                <iframe className="w-full" style={{ height: '36rem' }} allowFullScreen src={video?.url}></iframe>
            </div>
            <div className="flex flex-col w-full">
                <div className="flex flex-col pt-5 w-full ">
                    {loading
                        ?
                        <div className="w-full mx-auto pt-12">
                            <div className="animate-pulse flex space-x-4">
                                <div className="flex-1 space-y-4 py-1">
                                    <div className="h-4 bg-gray-400 bg-opacity-25 rounded w-1/6"></div>
                                    <div className="h-4 bg-gray-400 bg-opacity-25 rounded w-1/5"></div>
                                    <div className="space-y-2">
                                        <div className="h-4 bg-gray-400 bg-opacity-25 rounded w-3/5"></div>
                                        <div className="h-4 bg-gray-400 bg-opacity-25 rounded w-3/4"></div>
                                        <div className="h-4 bg-gray-400 bg-opacity-25 rounded w-2/5"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className='flex items-start'>
                            <img src={video.vod_profile_img} className="bg-cover rounded-md" />
                            <div className='flex flex-col ml-5 justify-center'>
                                <span className="text-xl font-semibold">{video?.titulo}</span>
                                <span className="font-medium text-lg text-gray-300">{video.descricao}</span>
                                <span className="font-medium text-lg text-gray-300">{video.data_envio_formatada}</span>
                            </div>
                        </div>
                    }
                </div>
                <div className="flex flex-col mt-5">
                    <span className='font-semibold text-lg'>Mais vídeos</span>
                    <div className='mt-2 w-full grid grid-cols-5 gap-10'>
                        {videos.map((video, index) => (
                            <div onClick={() => mudarVideo(video)} key={`${index}-${video.uuid}`}>
                                <div className="bg-gray-800 rounded cursor-pointer">
                                    <img src={video.capa} />
                                    <div className='flex flex-col py-1 px-2'>
                                        <span className='text-lg truncate'>{video.titulo}</span>
                                        <span className='text-md font-medium text-gray-300'>{video.descricao}</span>
                                        <span className="font-sm text-lg text-gray-300">{video.data_envio_formatada}</span>
                                    </div>

                                </div>
                            </div>
                        ))}
                        <div ref={loader} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WatchVOD;