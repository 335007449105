import React, { Component } from 'react'

import Rect from '../models/Rect'
import ReactDOM from "react-dom"
import EPGData from '../utils/EPGData'
import EPGUtils from '../utils/EPGUtils'
import * as api from '../../../service/api'
import Loading from '../../loading'

import * as moment from 'moment';
import 'moment/locale/pt-br';

const $ = document.querySelector.bind(document)

export default class TVGuide extends Component {


    static DAYS_BACK_MILLIS = 1 * 24 * 60 * 60 * 1000        // 3 days
    static DAYS_FORWARD_MILLIS = 1 * 24 * 60 * 60 * 1000     // 3 days
    static HOURS_IN_VIEWPORT_MILLIS = 2 * 60 * 60 * 1000     // 2 hours
    static TIME_LABEL_SPACING_MILLIS = 30 * 60 * 1000        // 30 minutes

    static VISIBLE_CHANNEL_COUNT = 5 // No of channel to show at a time
    static VERTICAL_SCROLL_BOTTOM_PADDING_ITEM = 1
    static VERTICAL_SCROLL_TOP_PADDING_ITEM = 1

    constructor(props) {
        super(props)
        this.handleClick = this.handleClick.bind(this)
        this.handleScroll = this.handleScroll.bind(this)
        this.handleMouseMove = this.handleMouseMove.bind(this)
        // this.onKeyDown = this.onKeyDown.bind(this)
        // this.epgData = this.props.epgData
        this.epg = null
        this.epgData = null
        this.epgUtils = new EPGUtils()

        this.scrollX = 0
        this.scrollY = 0
        this.focusedChannelPosition = 3
        this.focusedEventPosition = -1
        //this.state = {translate3d : `translate3d(${this.scrollX}px, 0px, 0px)`}
        //this.translate3d = `translate3d(${this.scrollX}px, 0px, 0px)`

        this.mChannelImageCache = new Map()

        this.mClipRect = new Rect()
        this.mDrawingRect = new Rect()
        this.mMeasuringRect = new Rect()

        this.mChannelLayoutMargin = 3
        this.mChannelLayoutPadding = 8
        this.mChannelLayoutHeight = 90
        this.mChannelLayoutWidth = 140

        this.mEPGBackground = '#1e1e1e'
        this.mChannelLayoutBackground = '#323232'
        this.mEventLayoutBackground = '#222222'
        this.mEventLayoutBackgroundCurrent = '#3b3b3b'
        this.mEventLayoutBackgroundFocus = '#b47323'
        this.mEventLayoutTextColor = '#d6d6d6'

        this.mEventLayoutTextSize = 30
        this.mTimeBarHeight = 45
        this.mTimeBarTextSize = 14
        this.mTimeBarLineWidth = 5
        this.mTimeBarLineColor = 'rgba(255, 255, 255, 0.3)'

        this.mResetButtonSize = 40
        this.mResetButtonMargin = 10
        this.interval = null

        //this.resetBoundaries()

    }

    resetBoundaries() {
        this.mMillisPerPixel = this.calculateMillisPerPixel()
        this.mTimeOffset = this.calculatedBaseLine()
        this.mTimeLowerBoundary = this.getTimeFrom(0)
        this.mTimeUpperBoundary = this.getTimeFrom(this.getWidth())
    }

    calculateMaxHorizontalScroll() {
        this.mMaxHorizontalScroll = parseInt(((TVGuide.DAYS_BACK_MILLIS + TVGuide.DAYS_FORWARD_MILLIS - TVGuide.HOURS_IN_VIEWPORT_MILLIS) / this.mMillisPerPixel))
    }

    calculateMaxVerticalScroll() {
        let maxVerticalScroll = this.getTopFrom(this.epgData.getChannelCount() - 2) + this.mChannelLayoutHeight
        this.mMaxVerticalScroll = maxVerticalScroll < this.getHeight() ? 0 : maxVerticalScroll - this.getHeight()
    }

    calculateMillisPerPixel() {
        return TVGuide.HOURS_IN_VIEWPORT_MILLIS / (this.getWidth() - this.mChannelLayoutWidth - this.mChannelLayoutMargin)
    }

    calculatedBaseLine() {
        //return LocalDateTime.now().toDateTime().minusMillis(DAYS_BACK_MILLIS).getMillis()
        return Date.now() - TVGuide.DAYS_BACK_MILLIS
    }

    getProgramPosition(channelPosition, time) {
        let events = this.epgData.getEvents(channelPosition)
        if (events != null) {
            for (let eventPos = 0; eventPos < events.length; eventPos++) {
                let event = events[eventPos]
                if (event.inicio <= time && event.fim >= time) {
                    return eventPos
                }
            }
        }
        return -1
    }

    getFirstVisibleChannelPosition() {
        let y = this.getScrollY(false)

        let position = parseInt((y - this.mChannelLayoutMargin - this.mTimeBarHeight)
            / (this.mChannelLayoutHeight + this.mChannelLayoutMargin))

        if (position < 0) {
            position = 0
        }
        return position
    }

    getLastVisibleChannelPosition() {
        let y = this.getScrollY(false)
        let totalChannelCount = this.epgData.getChannelCount()
        let screenHeight = this.getHeight()
        let position = parseInt((y + screenHeight + this.mTimeBarHeight - this.mChannelLayoutMargin)
            / (this.mChannelLayoutHeight + this.mChannelLayoutMargin))

        if (position > totalChannelCount - 1) {
            position = totalChannelCount - 1
        }

        // Add one extra row if we don't fill screen with current..
        return (y + screenHeight) > (position * this.mChannelLayoutHeight) && position < totalChannelCount - 1 ? position + 1 : position
    }

    getXFrom(time) {
        return parseInt(((time - this.mTimeLowerBoundary) / this.mMillisPerPixel) + this.mChannelLayoutMargin + this.mChannelLayoutWidth + this.mChannelLayoutMargin)
    }

    getTopFrom(position) {
        let y = position * (this.mChannelLayoutHeight + this.mChannelLayoutMargin)
            + this.mChannelLayoutMargin + this.mTimeBarHeight
        return y - this.getScrollY(false)
    }

    getXPositionStart() {
        return this.getXFrom(Date.now() - (TVGuide.HOURS_IN_VIEWPORT_MILLIS / 2))
    }


    getTimeFrom(x) {
        return (x * this.mMillisPerPixel) + this.mTimeOffset
    }

    shouldDrawTimeLine(now) {
        return now >= this.mTimeLowerBoundary && now < this.mTimeUpperBoundary
    }

    isEventVisible(start, end) {
        return (start >= this.mTimeLowerBoundary && start <= this.mTimeUpperBoundary)
            || (end >= this.mTimeLowerBoundary && end <= this.mTimeUpperBoundary)
            || (start <= this.mTimeLowerBoundary && end >= this.mTimeUpperBoundary)
    }

    getFocusedChannelPosition() {
        return this.focusedChannelPosition
    }

    getFocusedEventPosition() {
        return this.focusedEventPosition
    }

    isRTL() {
        return false
    }

    getScrollX(neglect = true) {
        if (neglect) {
            return 0
        }
        return this.scrollX
        //return window.scrollX
    }

    getScrollY(neglect = true) {
        if (neglect) {
            return 0
        }
        return this.scrollY
        //return window.scrollY
    }

    getWidth() {
        // return this.props.width
        return $("#date_control").offsetWidth
    }

    getHeight() {
        return this.mTimeBarHeight + (this.mChannelLayoutMargin + this.mChannelLayoutHeight) * TVGuide.VISIBLE_CHANNEL_COUNT
    }

    onDraw(canvas) {

        if (this.epgData != null && this.epgData.hasData()) {
            this.mTimeLowerBoundary = this.getTimeFrom(this.getScrollX(false))
            this.mTimeUpperBoundary = this.getTimeFrom(this.getScrollX(false) + this.getWidth())

            let drawingRect = this.mDrawingRect
            //console.log("X:" + this.getScrollX())
            drawingRect.left = this.getScrollX()
            drawingRect.top = this.getScrollY()
            drawingRect.right = drawingRect.left + this.getWidth()
            drawingRect.bottom = drawingRect.top + this.getHeight()

            this.drawChannelListItems(canvas, drawingRect)
            this.drawEvents(canvas, drawingRect)
            this.drawTimebar(canvas, drawingRect)
            this.drawTimeLine(canvas, drawingRect)
            this.drawFocusEvent(canvas, drawingRect)
        }
    }

    drawTimebar(canvas, drawingRect) {

        drawingRect.left = this.getScrollX() + this.mChannelLayoutWidth + this.mChannelLayoutMargin
        drawingRect.top = this.getScrollY()
        drawingRect.right = drawingRect.left + this.getWidth()
        drawingRect.bottom = drawingRect.top + this.mTimeBarHeight

        this.mClipRect.left = this.getScrollX() + this.mChannelLayoutWidth + this.mChannelLayoutMargin
        this.mClipRect.top = this.getScrollY()
        this.mClipRect.right = this.getScrollX() + this.getWidth()
        this.mClipRect.bottom = this.mClipRect.top + this.mTimeBarHeight

        //canvas.save()
        //canvas.rect(this.mClipRect.left, this.mClipRect.top, this.mClipRect.width, this.mClipRect.height)
        //canvas.clip()


        // Background
        canvas.fillStyle = this.mChannelLayoutBackground
        canvas.fillRect(drawingRect.left, drawingRect.top, drawingRect.width, drawingRect.height)

        // Time stamps
        //mPaint.setColor(mEventLayoutTextColor)
        //mPaint.setTextSize(mTimeBarTextSize)
        canvas.fillStyle = this.mEventLayoutTextColor
        if (this.isRTL()) {
            //canvas.setTransform(1, 0, 0, 1, 0, 0)
            //canvas.save()
            canvas.setTransform(1, 0, 0, 1, 0, 0)
        }

        for (let i = 0; i < TVGuide.HOURS_IN_VIEWPORT_MILLIS / TVGuide.TIME_LABEL_SPACING_MILLIS; i++) {
            // Get time and round to nearest half hour
            let time = TVGuide.TIME_LABEL_SPACING_MILLIS *
                (((this.mTimeLowerBoundary + (TVGuide.TIME_LABEL_SPACING_MILLIS * i)) +
                    (TVGuide.TIME_LABEL_SPACING_MILLIS / 2)) / TVGuide.TIME_LABEL_SPACING_MILLIS)

            if (this.isRTL()) {
                canvas.fillText(this.epgUtils.getShortTime(time),
                    (this.getWidth() + this.mChannelLayoutMargin + this.mChannelLayoutMargin - this.mChannelLayoutHeight) - this.getXFrom(time),
                    drawingRect.top + (((drawingRect.bottom - drawingRect.top) / 2) + (this.mTimeBarTextSize / 2)))
            } else {
                canvas.fillText(this.epgUtils.getShortTime(time),
                    this.getXFrom(time),
                    drawingRect.top + (((drawingRect.bottom - drawingRect.top) / 2) + (this.mTimeBarTextSize / 2)))
            }
        }
        if (this.isRTL()) {
            this.ctx.setTransform(-1, -0, 0, 1, this.getWidth(), 0)
            //canvas.restore()
        }

        //canvas.restore()

        this.drawTimebarDayIndicator(canvas, drawingRect)
        this.drawTimebarBottomStroke(canvas, drawingRect)
    }

    drawTimebarDayIndicator(canvas, drawingRect) {
        drawingRect.left = this.getScrollX()
        drawingRect.top = this.getScrollY()
        drawingRect.right = drawingRect.left + this.mChannelLayoutWidth
        drawingRect.bottom = drawingRect.top + this.mTimeBarHeight

        // Background
        //mPaint.setColor(mChannelLayoutBackground)
        canvas.fillStyle = this.mChannelLayoutBackground
        //canvas.drawRect(drawingRect, mPaint)
        canvas.fillRect(drawingRect.left, drawingRect.top, drawingRect.width, drawingRect.height)

        // Text
        //mPaint.setColor(mEventLayoutTextColor)
        canvas.fillStyle = this.mEventLayoutTextColor
        //mPaint.setTextSize(mTimeBarTextSize)
        //mPaint.setTextAlign(Paint.Align.CENTER)
        canvas.textAlign = "center"
        //canvas.drawText(EPGUtil.getWeekdayName(mTimeLowerBoundary),
        //drawingRect.left + ((drawingRect.right - drawingRect.left) / 2),
        //drawingRect.top + (((drawingRect.bottom - drawingRect.top) / 2) + (mTimeBarTextSize / 2)), mPaint)
        if (this.isRTL()) {
            //canvas.save()
            canvas.setTransform(1, 0, 0, 1, 0, 0)
            //canvas.scale(-1, 1)

            canvas.fillText(this.epgUtils.getWeekdayName(this.mTimeLowerBoundary),
                (this.getWidth() + this.mChannelLayoutMargin + this.mChannelLayoutMargin - this.mChannelLayoutHeight) - drawingRect.left + ((drawingRect.right - drawingRect.left) / 2),
                drawingRect.top + (((drawingRect.bottom - drawingRect.top) / 2) + (this.mTimeBarTextSize / 2))
            )
        } else {
            canvas.fillText(this.epgUtils.getWeekdayName(this.mTimeLowerBoundary),
                drawingRect.left + ((drawingRect.right - drawingRect.left) / 2),
                drawingRect.top + (((drawingRect.bottom - drawingRect.top) / 2) + (this.mTimeBarTextSize / 2))
            )
        }

        if (this.isRTL()) {
            this.ctx.setTransform(-1, -0, 0, 1, this.getWidth(), 0)
        }

        //mPaint.setTextAlign(Paint.Align.LEFT)
        canvas.textAlign = "left"
    }

    drawTimebarBottomStroke(canvas, drawingRect) {
        drawingRect.left = this.getScrollX()
        drawingRect.top = this.getScrollY() + this.mTimeBarHeight
        drawingRect.right = drawingRect.left + this.getWidth()
        drawingRect.bottom = drawingRect.top + this.mChannelLayoutMargin

        // Bottom stroke
        //mPaint.setColor(mEPGBackground)
        canvas.fillStyle = this.mEPGBackground
        canvas.fillRect(drawingRect.left, drawingRect.top, drawingRect.width, drawingRect.height)
    }

    drawTimeLine(canvas, drawingRect) {
        let now = Date.now()
        if (this.shouldDrawTimeLine(now)) {
            drawingRect.left = this.getXFrom(now)
            drawingRect.top = this.getScrollY()
            drawingRect.right = drawingRect.left + this.mTimeBarLineWidth
            drawingRect.bottom = drawingRect.top + this.getHeight()

            //mPaint.setColor(mTimeBarLineColor)
            canvas.fillStyle = this.mTimeBarLineColor
            //canvas.drawRect(drawingRect, mPaint)
            canvas.fillRect(drawingRect.left, drawingRect.top, drawingRect.width, drawingRect.height)
        }

    }

    drawEvents(canvas, drawingRect) {
        let firstPos = this.getFirstVisibleChannelPosition()
        let lastPos = this.getLastVisibleChannelPosition()

        // console.log("First: " + firstPos + " Last: " + lastPos)

        for (let pos = firstPos; pos <= lastPos; pos++) {
            // Set clip rectangle
            this.mClipRect.left = this.getScrollX() + this.mChannelLayoutWidth + this.mChannelLayoutMargin
            this.mClipRect.top = this.getTopFrom(pos)
            this.mClipRect.right = this.getScrollX() + this.getWidth()
            this.mClipRect.bottom = this.mClipRect.top + this.mChannelLayoutHeight

            //canvas.save()
            //canvas.rect(this.mClipRect.left, this.mClipRect.top, this.mClipRect.width, this.mClipRect.height)
            //canvas.clip()

            // Draw each event
            let foundFirst = false

            let epgEvents = this.epgData.getEvents(pos)
            if (this.isRTL()) {
                //canvas.setTransform(1, 0, 0, 1, 0, 0)
                //canvas.textAlign = "right"
            }

            for (let event of epgEvents) {
                if (this.isEventVisible(event.inicio, event.fim)) {
                    this.drawEvent(canvas, pos, event, drawingRect)
                    foundFirst = true
                } else if (foundFirst) {
                    break
                }
            }

            if (this.isRTL()) {
                //this.ctx.setTransform(-1, -0, 0, 1, this.getWidth(), 0)
            }

            //canvas.restore()
        }

    }

    drawEvent(canvas, channelPosition, event, drawingRect) {

        this.setEventDrawingRectangle(channelPosition, event.inicio, event.fim, drawingRect)

        canvas.fillStyle = EPGData.isEventCurrent(event) ? this.mEventLayoutBackgroundCurrent : this.mEventLayoutBackground
        if (drawingRect.left < this.getScrollX() + this.mChannelLayoutWidth + this.mChannelLayoutMargin) {
            drawingRect.left = this.getScrollX() + this.mChannelLayoutWidth + this.mChannelLayoutMargin
        }
        canvas.fillRect(drawingRect.left, drawingRect.top, drawingRect.width, drawingRect.height)

        drawingRect.left += this.mChannelLayoutPadding
        drawingRect.right -= this.mChannelLayoutPadding

        canvas.fillStyle = this.mEventLayoutTextColor
        canvas.font = "25px Roboto"

        drawingRect.top += (((drawingRect.bottom - drawingRect.top) / 3))

        let title = `${event.titulo}`
        let category = `${event.categorias}`
        let time = this.tempoEvento(event)
        // title = title.substring(0,
        //     mPaint.breakText(title, true, drawingRect.right - drawingRect.left, null))
        if (this.isRTL()) {
            console.log("LEFT :" + drawingRect.left)
            canvas.fillText(title, drawingRect.left, drawingRect.top)
        } else {
            let channel = this.epgData.getChannel(channelPosition)
            canvas.fillText(`${title} ${(channel.disponivel && EPGData.isEventCurrent(event)) ? ' - Disponível agora' : ''}`, drawingRect.left, drawingRect.top)
            canvas.font = "20px Roboto"
            canvas.fillText(category, drawingRect.left, drawingRect.top + 22)
            canvas.fillText(time, drawingRect.left, drawingRect.top + 45)
        }

    }

    setEventDrawingRectangle(channelPosition, start, end, drawingRect) {
        drawingRect.left = this.getXFrom(start)
        drawingRect.top = this.getTopFrom(channelPosition)
        drawingRect.right = this.getXFrom(end) - this.mChannelLayoutMargin
        drawingRect.bottom = drawingRect.top + this.mChannelLayoutHeight

        return drawingRect
    }


    drawChannelListItems(canvas, drawingRect) {
        // Background
        this.mMeasuringRect.left = this.getScrollX()
        this.mMeasuringRect.top = this.getScrollY()
        this.mMeasuringRect.right = drawingRect.left + this.mChannelLayoutWidth
        this.mMeasuringRect.bottom = this.mMeasuringRect.top + this.getHeight()

        //mPaint.setColor(mChannelLayoutBackground)
        canvas.fillStyle = this.mChannelLayoutBackground
        canvas.fillRect(this.mMeasuringRect.left, this.mMeasuringRect.top, this.mMeasuringRect.width, this.mMeasuringRect.height)

        let firstPos = this.getFirstVisibleChannelPosition()
        let lastPos = this.getLastVisibleChannelPosition()

        for (let pos = firstPos; pos <= lastPos; pos++) {
            this.drawChannelItem(canvas, pos, drawingRect)
        }
    }

    drawChannelItem(canvas, position, drawingRect) {
        drawingRect.left = this.getScrollX()
        drawingRect.top = this.getTopFrom(position)
        drawingRect.right = drawingRect.left + this.mChannelLayoutWidth
        drawingRect.bottom = drawingRect.top + this.mChannelLayoutHeight

        let imageURL = this.epgData.getChannel(position).icone

        if (this.mChannelImageCache.has(imageURL)) {
            let image = this.mChannelImageCache.get(imageURL)
            drawingRect = this.getDrawingRectForChannelImage(drawingRect, image)
            //canvas.drawBitmap(image, null, drawingRect, null)
            if (this.isRTL()) {
                canvas.setTransform(1, 0, 0, 1, 0, 0)
                canvas.drawImage(image, (this.getWidth() + 4 * this.mChannelLayoutMargin - this.mChannelLayoutWidth) - drawingRect.left, drawingRect.top, drawingRect.width, drawingRect.height)
                canvas.setTransform(-1, -0, 0, 1, this.getWidth(), 0)
            } else {
                canvas.drawImage(image, drawingRect.left, drawingRect.top, drawingRect.width, drawingRect.height)
            }

        } else {
            let img = new Image()
            img.src = imageURL
            let that = this
            img.onload = function () {
                that.mChannelImageCache.set(imageURL, img)
                that.updateCanvas()
                //drawingRect = that.getDrawingRectForChannelImage(drawingRect, img)
                //canvas.drawBitmap(image, null, drawingRect, null)
                //canvas.drawImage(img, drawingRect.left, drawingRect.top, drawingRect.width, drawingRect.height)
            }
        }
    }

    getDrawingRectForChannelImage(drawingRect, image) {
        drawingRect.left += this.mChannelLayoutPadding
        drawingRect.top += this.mChannelLayoutPadding
        drawingRect.right -= this.mChannelLayoutPadding
        drawingRect.bottom -= this.mChannelLayoutPadding

        let imageWidth = image.width
        let imageHeight = image.height
        let imageRatio = imageHeight / parseFloat(imageWidth)

        let rectWidth = drawingRect.right - drawingRect.left
        let rectHeight = drawingRect.bottom - drawingRect.top

        // Keep aspect ratio.
        if (imageWidth > imageHeight) {
            let padding = parseInt((rectHeight - (rectWidth * imageRatio)) / 2)
            drawingRect.top += padding
            drawingRect.bottom -= padding
        } else if (imageWidth <= imageHeight) {
            let padding = parseInt((rectWidth - (rectHeight / imageRatio)) / 2)
            drawingRect.left += padding
            drawingRect.right -= padding
        }
        return drawingRect
    }

    getChannelPosition(y) {
        // y -= this.mTimeBarHeight;
        let channelPosition = (y + this.mChannelLayoutMargin)
            / (this.mChannelLayoutHeight + this.mChannelLayoutMargin);

        return Math.floor(channelPosition);
    }

    drawFocusEvent(canvas, drawingRect) {

    }

    handleClick(e) {
        let el = this.refs.canvas
        let elemLeft = el.offsetLeft
        let elemTop = el.offsetTop
        let context = el.getContext('2d')
        let elements = [];

        let x = e.pageX - elemLeft - this.mChannelLayoutWidth - this.mChannelLayoutMargin
        let y = e.pageY - elemTop - this.mTimeBarHeight

        if (x > 0 && y > 0) {
            let channelPosition = this.getChannelPosition(this.scrollY + y)
            let programPosition = this.getProgramPosition(channelPosition, this.getTimeFrom(this.scrollX + x))
            let channel = this.epgData.channels[channelPosition]
            let event = channel.eventos[programPosition]

            this.props.history.push(`/app/detalhe/${event.epg_uuid}`)
            // console.log(channel, event)      
        }
    }

    handleMouseMove(e) {
        // console.log(e.clientX)
    }

    clear() {
        this.mClipRect = new Rect()
        this.mDrawingRect = new Rect()
        this.mMeasuringRect = new Rect()
    }

    recalculateAndRedraw(withAnimation) {
        if (this.epgData != null && this.epgData.hasData()) {
            this.resetBoundaries()

            this.calculateMaxVerticalScroll()
            this.calculateMaxHorizontalScroll()

            this.scrollX = this.getScrollX() + this.getXPositionStart() - this.getScrollX()
            this.scrollY = this.getScrollY()
            this.updateCanvas()
            // let pos = this.epgData.getChannelPosition(this.props.conteudo)
            // let pos = 3
            // let offset = this.mChannelLayoutHeight + this.mChannelLayoutMargin
            // let dy = 0
            // for (let i = 1; i <= pos; i++) {
            //     if (i > (TVGuide.VISIBLE_CHANNEL_COUNT - TVGuide.VERTICAL_SCROLL_BOTTOM_PADDING_ITEM)) {
            //         if (i <= (this.epgData.getChannelCount() - TVGuide.VERTICAL_SCROLL_BOTTOM_PADDING_ITEM)) {
            //             this.changeScrollPosition(i)
            //         }
            //     }
            // }
            // this.focusedChannelPosition = pos
            // this.focusedEventPosition = this.getProgramPosition(this.focusedChannelPosition, this.getTimeFrom(this.getScrollX(false) + this.getWidth() / 2))
            // this.focusedEvent = this.epgData.getEvent(this.focusedChannelPosition, this.focusedEventPosition)

            this.ctx.clearRect(0, 0, this.getWidth(), this.getHeight())
            this.clear()
            this.onDraw(this.ctx)
        }
    }

    changeScrollPosition = (channelPosition) => {
        if (channelPosition < this.epgData.getChannelCount()) {
            let dy = this.mChannelLayoutHeight + this.mChannelLayoutMargin
            if (channelPosition > (TVGuide.VISIBLE_CHANNEL_COUNT - TVGuide.VERTICAL_SCROLL_BOTTOM_PADDING_ITEM)) {
                if (channelPosition <= (this.epgData.getChannelCount() - TVGuide.VERTICAL_SCROLL_BOTTOM_PADDING_ITEM)) {
                    this.scrollY = this.getScrollY(false) + dy
                }
            }
        }
    }

    handleScroll(e) {
        let channelPosition = this.getFocusedChannelPosition()
        let dy = this.mChannelLayoutHeight + this.mChannelLayoutMargin
        if (e.deltaY < 0) {
            channelPosition -= 1
            if (channelPosition >= 3) {
                if (channelPosition >= (TVGuide.VISIBLE_CHANNEL_COUNT - TVGuide.VERTICAL_SCROLL_BOTTOM_PADDING_ITEM)) {
                    if (this.epgData.getChannelCount() - channelPosition != TVGuide.VERTICAL_SCROLL_BOTTOM_PADDING_ITEM) {
                        this.scrollY = this.getScrollY(false) - dy
                    }
                }
                this.focusedChannelPosition = channelPosition
                // if (this.props.onEventMove)
                //     this.props.onEventMove(this.focusedEvent)
            }

            // this.scrollY = this.getScrollY(false) - dy
        } else if (e.deltaY > 0) {
            channelPosition += 1
            if (channelPosition < this.epgData.getChannelCount()) {
                if (channelPosition > (TVGuide.VISIBLE_CHANNEL_COUNT - TVGuide.VERTICAL_SCROLL_BOTTOM_PADDING_ITEM)) {
                    if (channelPosition <= (this.epgData.getChannelCount() - TVGuide.VERTICAL_SCROLL_BOTTOM_PADDING_ITEM)) {
                        this.scrollY = this.getScrollY(false) + dy
                    }
                }
                this.focusedChannelPosition = channelPosition
                // if (this.props.onEventMove)
                //     this.props.onEventMove(this.focusedEvent)
            }
        }

        this.ctx.clearRect(0, 0, this.getWidth(), this.getHeight())
        this.clear()
        this.onDraw(this.ctx)
        e.preventDefault()
    }

    onKeyDown = (event) => {
        let keyCode = event.keyCode
        /*keyCode = this.isRTL() && (keyCode == 39) ? 37 : 39
        keyCode = this.isRTL() && (keyCode == 37) ? 39 : 37*/
        let programPosition = this.getFocusedEventPosition()
        let channelPosition = this.getFocusedChannelPosition()
        let dx = 0, dy = 0
        switch (keyCode) {
            case 39:
                //let programPosition = this.getProgramPosition(this.getFocusedChannelPosition(), this.getTimeFrom(this.getScrollX(false) ))
                programPosition += 1
                if (programPosition != -1 && programPosition < this.epgData.getEventCount(this.getFocusedChannelPosition())) {
                    this.focusedEvent = this.epgData.getEvent(this.getFocusedChannelPosition(), programPosition)
                    if (this.focusedEvent) {
                        this.focusedEventPosition = programPosition
                        dx = parseInt((this.focusedEvent.fim - this.focusedEvent.inicio) / this.mMillisPerPixel)
                    }
                }
                this.scrollX = this.getScrollX(false) + dx
                if (this.props.onEventMove)
                    this.props.onEventMove(this.focusedEvent)
                break
            case 37:
                programPosition -= 1
                if (programPosition != -1 && programPosition > -1) {
                    this.focusedEvent = this.epgData.getEvent(this.getFocusedChannelPosition(), programPosition)
                    if (this.focusedEvent) {
                        this.focusedEventPosition = programPosition
                        dx = (-1) * parseInt((this.focusedEvent.fim - this.focusedEvent.inicio) / this.mMillisPerPixel)
                    }
                }
                this.scrollX = this.getScrollX(false) + dx
                if (this.props.onEventMove)
                    this.props.onEventMove(this.focusedEvent)
                break
            case 40:
                channelPosition += 1
                if (channelPosition < this.epgData.getChannelCount()) {

                    dy = this.mChannelLayoutHeight + this.mChannelLayoutMargin
                    this.focusedEventPosition = this.getProgramPosition(channelPosition, this.getTimeFrom(this.getScrollX(false) + this.getWidth() / 2))
                    this.focusedEvent = this.epgData.getEvent(channelPosition, this.focusedEventPosition)

                    if (channelPosition > (TVGuide.VISIBLE_CHANNEL_COUNT - TVGuide.VERTICAL_SCROLL_BOTTOM_PADDING_ITEM)) {
                        if (channelPosition <= (this.epgData.getChannelCount() - TVGuide.VERTICAL_SCROLL_BOTTOM_PADDING_ITEM)) {
                            this.scrollY = this.getScrollY(false) + dy
                        }
                    }
                    // console.log(channelPosition)
                    this.focusedChannelPosition = channelPosition
                    if (this.props.onEventMove)
                        this.props.onEventMove(this.focusedEvent)
                }
                break
            case 38:
                channelPosition -= 1
                if (channelPosition >= 0) {

                    dy = (-1) * (this.mChannelLayoutHeight + this.mChannelLayoutMargin)
                    this.focusedEventPosition = this.getProgramPosition(channelPosition, this.getTimeFrom(this.getScrollX(false) + this.getWidth() / 2))
                    this.focusedEvent = this.epgData.getEvent(channelPosition, this.focusedEventPosition)

                    if (channelPosition >= (TVGuide.VISIBLE_CHANNEL_COUNT - TVGuide.VERTICAL_SCROLL_BOTTOM_PADDING_ITEM)) {
                        if (this.epgData.getChannelCount() - channelPosition != TVGuide.VERTICAL_SCROLL_BOTTOM_PADDING_ITEM) {
                            this.scrollY = this.getScrollY(false) + dy
                        }
                    }
                    this.focusedChannelPosition = channelPosition
                    if (this.props.onEventMove)
                        this.props.onEventMove(this.focusedEvent)
                }
                break
            case 13:
                if (this.focusedChannelPosition != null && this.focusedEvent != null && this.props.epgEventKeyEnter)
                    this.props.epgEventKeyEnter(this.focusedEvent, this.epgData.getChannel(this.focusedChannelPosition))
                break
        }

        this.ctx.clearRect(0, 0, this.getWidth(), this.getHeight())
        this.clear()
        this.onDraw(this.ctx)
    }

    componentDidMount() {
        this.atualizarEPG((epg) => {
            this.setState({ loading: false }, () => {
                this.epgData = new EPGData(epg)
                this.recalculateAndRedraw(false)
                this.focusEPG()
                this.refs.canvas.addEventListener('wheel', this.handleScroll, false);
                // this.updateCanvas()
                this.interval = setInterval(() => {
                    this.ctx.clearRect(0, 0, this.getWidth(), this.getHeight())
                    this.clear()
                    this.onDraw(this.ctx)
                }, 60 * 1000);
            })
        })
    }

    atualizarEPG(cb) {
        api.pegarEPG(this.state.date.format('YYYY-MM-DD'))
            .then(res => {
                if (res.success) {
                    let epg = (this.state.apenasDisponiveis) ? res.epg.filter(canal => canal.disponivel) : res.epg
                    cb(epg)
                }
            })
    }

    componentWillUnmount() {
        if (this.refs.canvas)
            this.refs.canvas.removeEventListener('wheel', this.handleScroll, false);

        clearInterval(this.interval)
    }

    componentDidUpdate() {
    }

    updateCanvas() {
        this.ctx = this.refs.canvas.getContext('2d')

        if (this.isRTL()) {
            //this.ctx.scale(-1,1)
            //this.ctx.translate(this.getWidth(), 0)
            this.ctx.setTransform(-1, -0, 0, 1, this.getWidth(), 0)
            //this.ctx.setTransform(-1,-0,0,1,this.getWidth(),0)

            // WORKING
            /*this.ctx.setTransform(1,0,0,1,0,0)
            this.ctx.translate(this.getWidth(),0)
            this.ctx.scale(-1,1)*/
            //console.log(this.ctx.currentTransform)
            //this.ctx.rotate(360*Math.PI/180)
            //console.log(this.ctx.currentTransform)
        }
        this.onDraw(this.ctx)
    }

    focusEPG() {
        ReactDOM.findDOMNode(this.refs.epg).focus()
    }

    toDate(time) {
        if (time) {
            time = moment(Number(time)).format("HH:mm");
            return time
        }
    }

    tempoEvento = (evento) => {
        if (evento)
            return `${this.toDate(evento?.inicio)} - ${this.toDate(evento?.fim)}`
    }

    moveEvent = (direction) => {
        if (!this.state.loading) {
            switch (direction) {
                case 'left':
                    this.scrollX = this.getScrollX(false) - ((TVGuide.TIME_LABEL_SPACING_MILLIS / this.mMillisPerPixel) * 2)
                    break
                case 'right':
                    this.scrollX = this.getScrollX(false) + ((TVGuide.TIME_LABEL_SPACING_MILLIS / this.mMillisPerPixel) * 2)
                    break
            }

            this.ctx.clearRect(0, 0, this.getWidth(), this.getHeight())
            this.clear()
            this.onDraw(this.ctx)


            let dateAux = moment(this.state.date)
            let lowerBoundary = dateAux.startOf('day').valueOf()
            let upperBoundary = dateAux.endOf('day').valueOf()
            let timeFromX = this.getTimeFrom(this.scrollX)

            // console.log(moment(lowerBoundary).format(), moment(timeFromX).format(), moment(upperBoundary).format())

            if ((timeFromX < lowerBoundary) || (timeFromX > upperBoundary)) {
                this.setState({
                    loading: true,
                    date: moment(this.mTimeLowerBoundary).startOf('day')
                }, () => {
                    this.atualizarEPG((epg) => {
                        this.epgData = new EPGData(epg)
                        this.setState({ loading: false }, () => {
                            this.resetBoundaries()
                            this.updateCanvas()
                            this.calculateMaxVerticalScroll()
                            this.calculateMaxHorizontalScroll()
                            this.ctx.clearRect(0, 0, this.getWidth(), this.getHeight())
                            this.clear()
                            this.onDraw(this.ctx)
                            this.refs.canvas.addEventListener('wheel', this.handleScroll, false)
                        })
                    })
                })
            }
            // if (this.props.onEventMove)
            //     this.props.onEventMove(this.focusedEvent)
        }
    }

    changeDate(direction) {
        if (!this.state.loading) {
            let date = null
            switch (direction) {
                case 'left':
                    date = moment(this.state.date).subtract(1, 'day')
                    this.scrollX = this.getScrollX(false) - (TVGuide.DAYS_BACK_MILLIS / this.mMillisPerPixel)
                    break
                case 'right':
                    date = moment(this.state.date).add(1, 'day')
                    this.scrollX = this.getScrollX(false) + (TVGuide.DAYS_FORWARD_MILLIS / this.mMillisPerPixel)
                    break
            }
            this.setState({
                loading: true,
                date: date
            }, () => {
                this.atualizarEPG((epg) => {
                    this.epgData = new EPGData(epg)
                    this.setState({ loading: false }, () => {
                        this.resetBoundaries()
                        this.updateCanvas()
                        this.calculateMaxVerticalScroll()
                        this.calculateMaxHorizontalScroll()
                        this.ctx.clearRect(0, 0, this.getWidth(), this.getHeight())
                        this.clear()
                        this.onDraw(this.ctx)
                        this.refs.canvas.addEventListener('wheel', this.handleScroll, false);
                    })
                })
            })
        }
    }

    goToNow() {
        if (!this.state.loading) {

            this.setState({
                loading: true,
                date: moment()
            }, () => {
                this.atualizarEPG((epg) => {
                    this.epgData = new EPGData(epg)

                    this.setState({ loading: false }, () => {
                        this.recalculateAndRedraw(true)
                        this.refs.canvas.addEventListener('wheel', this.handleScroll, false);
                    })
                })
            })
        }
    }

    apenasDisponiveis() {
        if (this.state.apenasDisponiveis) {
            this.goToNow()
            this.setState({ apenasDisponiveis: !this.state.apenasDisponiveis })
        } else {
            this.setState({
                loading: true,
            }, () => {
                let disponiveis = this.epgData.channels.filter(canal => canal.disponivel)
                this.epgData = new EPGData(disponiveis)

                this.setState({ loading: false, apenasDisponiveis: !this.state.apenasDisponiveis }, () => {
                    this.recalculateAndRedraw(true)
                    this.refs.canvas.addEventListener('wheel', this.handleScroll, false);
                })
            })
        }
    }

    state = {
        loading: true,
        date: moment(),
        apenasDisponiveis: false
    }

    render() {
        return (
            <div className="flex flex-col container mx-auto pb-10">
                <div className="flex flex-row justify-between items-center">
                    <span className="m-5 text-3xl font-bold">Guia de programação</span>
                    <div className="flex items-center">
                        <input id="mostrar_todos" onChange={() => this.apenasDisponiveis()} checked={this.state.apenasDisponiveis} type="checkbox" />
                        <label className="ml-2 text-lg" htmlFor="mostrar_todos">Mostrar apenas disponíveis na web</label>
                    </div>
                </div>
                <div ref="epg">
                    <div id="date_control" className="flex items-center justify-center space-x-10 p-2 mb-2 w-full bg-white text-black">
                        <button onClick={() => this.changeDate('left')}><i className="fa fa-chevron-left" /></button>
                        <span className="text-xl font-bold">{this.state.date.format('D [de] MMMM')}</span>
                        <button onClick={() => this.changeDate('right')}><i className="fa fa-chevron-right" /></button>
                    </div>
                    <div id="date_control" className="flex items-center justify-center space-x-12 p-1 mb-2 w-full">
                        <div className="flex flex-col items-center justify-center">
                            <button onClick={() => this.moveEvent('left')}><i className="fa fa-chevron-left" /></button>
                            <span>-1h</span>
                        </div>
                        <button onClick={() => this.goToNow()} className="text-xl font-bold">Reiniciar linha do tempo</button>
                        <div className="flex flex-col items-center justify-center">
                            <button onClick={() => this.moveEvent('right')}><i className="fa fa-chevron-right" /></button>
                            <span>+1h</span>
                        </div>
                    </div>
                    {this.state.loading ?
                        <Loading />
                        : <canvas
                            ref="canvas"
                            id="canvas"
                            onClick={this.handleClick}
                            onMouseMove={this.handleMouseMove}
                            width={this.getWidth()}
                            height={this.getHeight()} />
                    }
                </div >
            </div >
        )
    }
}