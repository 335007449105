import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { checkIsActive } from '../service/utils';
import * as api from '../service/api'

const logoUrl = process.env.PUBLIC_URL + '/imagens/logo.svg';

function Header() {

    const [loading, setLoading] = useState(true)
    const [eventos, setEventos] = useState([])

    const location = useLocation()

    const onSearch = (e) => {
        let busca = e.target.value
        if (busca == 0)
            setEventos([])
        if (busca.length > 3) {
            setLoading(true)
            api.buscarTitulos(busca)
                .then(res => {
                    if (res.success) {
                        setEventos(res.eventos)
                    }
                })
        }
    }

    const toogleMenu = () => {
        const menu = document.getElementById('menu')
        if (menu.classList.contains('hidden'))
            menu.classList.remove('hidden')
        else
            menu.classList.add('hidden')
    }

    return (
        <div className="relative">
            <nav className="bg-gray-900 p-2 lg:p-0">
                <div className="container mx-auto flex items-center justify-between flex-wrap text-white">
                    <div className="flex items-center flex-shrink-0 mr-6">
                        <Link to="/app"><img className="w-24" src={logoUrl} alt='navbar-logo' /></Link>
                    </div>
                    <div onClick={() => toogleMenu()} className="block lg:hidden px-2 bg-blue-700 p-2 rounded hover:bg-blue-900 transition duration-200">
                        <i className="fa fa-bars"></i>
                    </div>
                    <div id="menu" className="hidden w-full flex-grow lg:flex lg:items-center lg:w-auto">
                        <div className="flex items-center">
                            <Link to="/app" className={`hidden lg:block mt-4 text-lg lg:mt-0 hover:text-orange-500 py-4 lg:px-2 lg:mr-4 ${checkIsActive(location, '/app') ? 'border-b-4 border-orange-600' : ''}`}>Início</Link>
                            <Link to="/app/guide" className={`hidden lg:block mt-4 text-lg lg:mt-0 hover:text-orange-500 py-4 lg:px-2 lg:mr-4 ${checkIsActive(location, '/app/guide') ? 'border-b-4 border-orange-600' : ''}`}>Guia</Link>
                            <Link to="/app/ativar" className={`block lg:inline-block text-lg hover:text-orange-500 py-4 lg:px-2 lg:mr-4 ${checkIsActive(location, '/app/ativar') ? 'border-b-4 border-orange-600' : ''}`}>Ativar novo dispositivo</Link>
                        </div>
                        <div className=" flex flex-1 justify-between lg:justify-end items-center py-2 lg:p-0">
                            <input onChange={onSearch} className="lg:ml-2 p-2 rounded lg:w-64 text-black hidden lg:block" placeholder="Pesquisar por titulos..." />
                            <Link to="/app/account" className="lg:block ml-2 bg-blue-500 p-2 rounded hover:bg-blue-700 transition duration-200 hidden">Minha conta</Link>
                            <Link to="/logout" className="lg:ml-5 hover:text-orange-500 text-lg">Sair</Link>
                        </div>
                    </div>
                </div>
            </nav>

            {eventos.length > 0 &&
                <div className="absolute bg-gray-900 border-2 border-gray-800 w-full z-50 p-5" style={{ height: 'auto' }}>
                    <div className="flex items-center justify-between">
                        <span className="text-xl font-bold py-2">Programas encontrados </span>
                        <button onClick={() => { setEventos([]) }} className="text-2xl m-4"><i className="fa fa-close"></i></button>
                    </div>
                    <div className="flex flex-row flex-wrap">
                        {eventos.map(evento => (
                            <div key={evento.fim} className="w-1/4 flex flex-col p-2 justify-start">
                                <img style={{ objectFit: 'contain' }} onError={e => { e.target.error = null; e.target.src = evento?.imagem_canal }} src={evento?.imagem} />
                                {/* {evento.current && <span className="bg-transparent self-start text-red-400 font-semibold px-2 border border-red-500 rounded py-1 mt-2">Ao vivo</span>} */}
                                <Link target="_blank" to={`/app/detalhe/${evento.epg_uuid}`} onClick={() => setEventos([])} className="text-lg mt-2">{evento.titulo}</Link>
                                <div className="flex items-center space-x-2">
                                    <span className="text-sm">{evento.inicio_formatado} - {evento.fim_formatado}</span>
                                    <img className="w-5" src={evento.classificacao} />
                                </div>
                                <span className="text-sm">{evento.subtitulo}</span>
                                <div className="mt-2 mr-5 h-20 scrollable">
                                    <span className="font-light text-sm">{evento.descricao}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            }
        </div>
    )
}

export default Header;