import React from 'react';

function Loading() {
    return (
        <svg className="spinner mx-auto mt-10" viewBox="0 0 50 50">
            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
        </svg>
    )
}

export default Loading;