import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Loading from '../components/loading';
import * as api from '../service/api'
import io from 'socket.io-client'
import Media from 'react-media';



function DeviceManager() {
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [dispositivos, setDispositivos] = useState([])

    useEffect(() => {
        buscarDispositivos()
    }, [])

    const buscarDispositivos = () => {
        setLoading(true)
        setTimeout(() => {
            api.dispositivos()
                .then(res => {
                    if (!res.success) {
                        if (res.code == 0) {
                            history.replace('/logout')
                        }
                        return
                    }
                    setLoading(false)
                    setDispositivos(res.dispositivos)
                })
        }, 500);
    }

    const removerDispositivo = (id) => {
        setLoading(true)
        api.removerDispositivo(id)
            .then(res => {
                if (!res.success) {
                    if (res.code == 0) {
                        history.replace('/logout')
                    }
                    setLoading(false)
                    return
                }

                let socket = io(api.SOCKET_FLUSSONIC)
                socket.on('connect', () => {
                    socket.emit('deactivation', res)
                    socket.disconnect()

                })

                buscarDispositivos()
            })
    }

    return (
        <div className="container mx-auto pt-5">
            <div className="flex justify-between items-center px-2">
                <span className="text-3xl">Meus dispositivos</span>
                <Link to="/app/account" className="text-blue-500 font-black">Voltar</Link>
            </div>

            <Media query="(max-width: 599px)" render={() =>
            (
                <div className="p-5">
                    {loading ?
                        <Loading />
                        : dispositivos.length == 0
                            ? (<center className="mt-10"><span className="text-2xl text-gray-500">Sem dispositivos...</span></center>)
                            : dispositivos.map((dispositivo, index) => (
                                <div key={index} className="flex flex-col bg-white text-black border border-gray-500 my-5 p-2">
                                    <span>Modelo: <strong>{dispositivo.modelo}</strong></span>
                                    <span>Tipo: <strong>{dispositivo.tipo}</strong></span>
                                    <span className="mt-2">Último acesso: <strong>{dispositivo.horario_formatado}</strong></span>
                                    <button onClick={() => removerDispositivo(dispositivo.id)} className="bg-red-500 text-white my-2 px-4 py-2 border rounded-md hover:bg-white hover:border-red-500 hover:text-black ">
                                        <i className="fa fa-remove" /> Remover
                                    </button>
                                </div>
                            ))
                    }
                </div>
            )} />

            <Media query="(min-width: 600px)" render={() =>
            (
                <div className="py-5">
                    {loading ?
                        <Loading />
                        :
                        dispositivos.length == 0
                            ? (<center className="mt-10"><span className="text-2xl text-gray-500">Sem dispositivos...</span></center>)
                            :
                            <table className="min-w-full table-auto text-black">
                                <thead>
                                    <tr className="bg-gray-800">
                                        <th className="py-2">
                                            <span className="text-gray-300">#</span>
                                        </th>
                                        <th className="py-2">
                                            <span className="text-gray-300">Dispositivo</span>
                                        </th>
                                        <th className="py-2">
                                            <span className="text-gray-300">Tipo</span>
                                        </th>
                                        <th className="py-2">
                                            <span className="text-gray-300">Último acesso</span>
                                        </th>

                                        <th className="py-2">
                                            <span className="text-gray-300">Ações</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-gray-200">
                                    {dispositivos.map((dispositivo, index) => (
                                        <tr key={index} className="bg-white border border-gray-500">
                                            <td className="px-16 py-2 flex flex-row items-center justify-center">
                                                {index + 1}
                                            </td>
                                            <td>
                                                <span className="text-center ml-2 font-semibold">{dispositivo.modelo}</span>
                                            </td>
                                            <td className="px-16 py-2">
                                                <span>{dispositivo.tipo}</span>
                                            </td>
                                            <td className="px-16 py-2">
                                                <span>{dispositivo.horario_formatado}</span>
                                            </td>
                                            <td className="px-16 py-2">
                                                <button onClick={() => removerDispositivo(dispositivo.id)} className="bg-red-500 text-white px-4 py-2 border rounded-md hover:bg-white hover:border-red-500 hover:text-black ">
                                                    <i className="fa fa-remove" /> Remover
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                    }
                </div>
            )} />

        </div>
    )
}

export default DeviceManager;