import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { Link, useHistory } from 'react-router-dom';
import Loading from '../components/loading';
import * as api from '../service/api'

function RegisterFreeAccount() {

    const history = useHistory()

    const [cpfVerificado, setCPFVerificado] = useState(false)
    const [cpf, setCPF] = useState("")
    const [contatoParaEnvio, setContatoParaEnvio] = useState(null)
    const [contatosWhatsApp, setContatosWhatsApp] = useState([])
    const [contatosEmail, setContatosEmail] = useState([])
    const [contatosSMS, setContatosSMS] = useState([])
    const [erroCPF, setErroCPF] = useState(null)
    const [feedback, setFeedback] = useState(null)
    const [loading, setLoading] = useState(false)


    const voltar = () => {
        setCPFVerificado(false)
        setCPF('')
        setErroCPF(null)
    }

    const border = <hr className="border-orange-700 my-2" />

    const getFeedback = (tipo, msg) => {
        switch (tipo) {
            case 'success':
                return (
                    <div className="bg-green-500 bg-opacity-50 rounded relative px-5 py-2 max-w-md">
                        <button className="absolute top-0 right-0 m-1" type="button" onClick={() => { setFeedback(null) }}>
                            <span>&times;</span>
                        </button>
                        <div>{msg}</div>
                    </div>
                )
            case 'error':
                return (
                    <div className="bg-red-600 bg-opacity-50 rounded relative px-5 py-2 max-w-md">
                        <button className="absolute top-0 right-0 m-1" type="button" onClick={() => { setFeedback(null) }}>
                            <span>&times;</span>
                        </button>
                        <div>{msg}</div>
                    </div>
                )
        }
    }

    const verificarCPF = () => {
        let cpfNumbers = cpf.replace(/\D/g, "")
        if (cpfNumbers.length != 11) {
            setErroCPF("Campo obrigatório")
            return
        }

        setLoading(true)
        api.verificarCPF(cpfNumbers).then(
            res => {
                setLoading(false)
                if (!res.success) {
                    setFeedback(getFeedback('error', res.message))
                    return
                }

                if (res.cliente_possui_tv) {
                    setFeedback(getFeedback('success', "Você possui um contrato com TV ativo"))
                }

                setFeedback(null)
                setContatosWhatsApp(res.contatos_whatsapp)
                setContatosEmail(res.contatos_email)
                setContatosSMS(res.contatos_sms)
                setCPFVerificado(true)
            }
        ).catch(e => {
            setLoading(false)
            setFeedback(getFeedback('error', "Algo deu errado"))
            console.error(e)
        })
    }

    const enviarAcessoCliente = () => {
        if (contatoParaEnvio == null) {
            setFeedback(getFeedback('error', "Escolha uma opção"))
            return
        }

        setLoading(true)

        api.enviarAcessoCliente({ id: contatoParaEnvio.contato.id, tipo: contatoParaEnvio.tipo })
            .then(res => {
                setLoading(false)
                if (!res.success) {
                    setFeedback(getFeedback('error', res.message))
                    return
                }

                setFeedback(getFeedback('success', res.message))
                setContatoParaEnvio(null)
                setTimeout(() => { history.replace('/') }, 5000)
            })
    }

    const contatosRender = () => (
        <div className="flex flex-col p-5">
            <span className="text-2xl">Como deseja receber as informações de acesso?</span>
            {border}

            {feedback}

            <div className="my-2">

                <span className="mb-2 font-semibold">Desejo receber as informações: </span>


                {contatosEmail.length > 0 && (
                    <div className="py-2">
                        <span className="mb-2 font-semibold">No E-mail:</span>
                        <div className="py-2">
                            {contatosEmail.map(
                                contato => (
                                    <div key={`email-${contato['id']}`} className='flex items-center space-x-1 text-lg font-semibold'>
                                        <input className='w-4 h-4' type="radio" checked={contatoParaEnvio?.contato?.id === contato.id && contatoParaEnvio?.tipo === 'email'} onChange={() => setContatoParaEnvio({ tipo: 'email', contato })} />
                                        <label onClick={() => setContatoParaEnvio({ tipo: 'email', contato })}> {` ${contato['contato']}`} </label>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                )}

                {contatosSMS.length > 0 && (
                    <div className="py-2">
                        <span className="mb-2 font-semibold">Por SMS no número:</span>
                        <div className="py-2">
                            {contatosSMS.map(contato =>
                                <div key={`sms-${contato['id']}`} className='flex items-center space-x-1 text-lg font-semibold'>
                                    <input className='w-4 h-4' type="radio" checked={contatoParaEnvio?.contato?.id === contato.id && contatoParaEnvio?.tipo === 'sms'} onChange={() => setContatoParaEnvio({ tipo: 'sms', contato })} />
                                    <label onClick={() => setContatoParaEnvio({ tipo: 'sms', contato })}> {` ${contato['contato']}`} </label>
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {contatosSMS.length > 0 && (
                    <div className="py-2">
                        <span className="mb-2 font-semibold">Por WhatsApp no número:</span>
                        <div className="py-2">
                            {contatosWhatsApp.map(contato =>
                                <div key={`whatsapp-${contato['id']}`} className='flex items-center space-x-1 text-lg font-semibold'>
                                    <input className='w-4 h-4' type="radio" checked={contatoParaEnvio?.contato?.id === contato.id && contatoParaEnvio?.tipo === 'whatsapp'} onChange={() => setContatoParaEnvio({ tipo: 'whatsapp', contato })} />
                                    <label onClick={() => setContatoParaEnvio({ tipo: 'whatsapp', contato })}> {` ${contato['contato']}`} </label>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
            <div className="mt-2 flex flex-row justify-between">
                <button type="button" className="bg-gray-600 hover:bg-gray-700 p-2 rounded mr-2" onClick={voltar} >Cancelar</button>
                <button type="submit" className="bg-blue-600 hover:bg-blue-700 p-2 rounded disabled:opacity-25" disabled={contatoParaEnvio == null} onClick={() => enviarAcessoCliente()}>Enviar</button>
            </div>
        </div>
    )

    const formCPFRender = () => (
        <div>
            <span className="text-2xl">Registrar conta gratuita</span>
            {border}

            {feedback}

            <div className="flex flex-col account-section mt-2">
                <div className="flex flex-col">
                    <label className="text-muted mb-1">Informe seu CPF</label>
                    <InputMask
                        mask="999.999.999-99"
                        className="p-2 rounded text-black"
                        required
                        value={cpf}
                        type="tel"
                        name="cpf"
                        autoComplete="off"
                        onChange={(e) => { setCPF(e.target.value) }} />
                    <div className="text-red-500 mt-1" style={{ width: '20rem' }}>
                        {erroCPF}
                    </div>
                </div>
                <div className="mt-5 flex flex-row justify-between">
                    <Link to="/"><button type="button" className="bg-gray-600 hover:bg-gray-700 p-2 rounded mr-2">Cancelar</button></Link>
                    <button onClick={() => verificarCPF()} type="submit" className="bg-blue-600 hover:bg-blue-700 p-2 rounded">Verificar CPF</button>
                </div>
            </div>

            <ul className="mt-5 ml-5 list-circle font-light text-gray-500">
                <li>A liberação dos canais do pacote de canais Livres e Abertos está disponibilizada para clientes com planos com valor a partir de R$ 99,90</li>
                {/* <li>Apenas clientes com planos com valor acima de R$ 99,90 <br /> poderão utilizar os canais Livre e Abertos do Júpiter TV </li> */}
            </ul>
        </div >
    )

    return (
        <div className="pt-20 px-5">
            <div className="flex flex-col lg:w-1/4 mx-auto">
                <img src={process.env.PUBLIC_URL + 'imagens/logo_jupitertv.svg'} className="mb-4 mx-auto" alt="logo" width="200px" />
                {loading
                    ?
                    <Loading />
                    :
                    cpfVerificado ? contatosRender() : formCPFRender()
                }
            </div>
        </div>
    )
}

export default RegisterFreeAccount;