import React, { useEffect, useState } from 'react'
import * as api from '../service/api'
import Programacoes from '../components/programacoes'
import Carousel from '../components/carousel/carousel'
import { useHistory } from 'react-router-dom'
import Canais from './Canais'
import CanaisVOD from './CanaisVOD'

function ListaCanais() {

    const history = useHistory()
    const [categorias, setCategorias] = useState([])
    const [loading, setLoading] = useState(true)
    const [mostrarEventosGenero, setMostrarEventosGenero] = useState(null)
    const [canalAtual, setCanalAtual] = useState(null)

    useEffect(() => {
        setTimeout(() => {
            api.conteudosComGeneros()
                .then(response => {
                    if (!response.success) {
                        if (response.code == 0)
                            history.replace('/logout')
                        return
                    }

                    setCategorias(response.generos)
                    setLoading(false)
                })
        }, 500);
    }, [])

    const changeEvent = (canal, genero) => {
        setCanalAtual(canal)
        setMostrarEventosGenero(genero)
    }

    return (
        <>

            <Canais />

            <CanaisVOD />

            {!loading &&
                <>
                    <div className="flex flex-row items-center lg:justify-start pl-10 mt-10">
                        <span className="text-2xl font-light">Disponíveis apenas no Set Top Box</span>
                    </div>

                    {categorias.map(genero => (
                        <div key={genero.id} className="mt-2">
                            <div className="flex flex-row pl-10 justify-between items-center lg:justify-start">
                                <span className="text-2xl font-light">{genero.descricao}</span>
                            </div>
                            <Carousel id={genero.id}>
                                {genero.conteudos.map((canal, index) => {

                                    if ((typeof canal.evento) == 'string')
                                        canal.evento = JSON.parse(canal.evento)
                                    return (
                                        <div onClick={() => { changeEvent(canal, genero.id) }} key={index} className="slide rounded cursor-pointer absolute">
                                            <img className="bg-cover" onError={e => { e.target.error = null; e.target.src = canal.icone }} src={canal?.evento?.imagem} alt={canal.evento.titulo} />
                                            <div className="absolute bottom-0 w-full flex flex-row justify-between p-2 bg-gray-800 bg-opacity-50">
                                                <div className="w-2/3 flex flex-row justify-start items-center">
                                                    <span className="truncate text-lg font-semibold">{canal?.evento?.titulo}</span>
                                                    <img className="w-5 ml-1" src={canal?.evento?.classificacao} />
                                                </div>
                                                <img className="w-1/3 self-end" src={canal.icone} alt={canal.titulo} />
                                            </div>
                                        </div>
                                    )
                                })}
                            </Carousel>
                            {mostrarEventosGenero == genero.id && <Programacoes id={genero.id} canalAtual={canalAtual} close={() => setMostrarEventosGenero(false)} />}
                        </div>
                    ))}
                </>
            }
        </>
    )
}

export default ListaCanais;