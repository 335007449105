import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Programacoes from '../components/programacoes';
import * as api from '../service/api'
import { splitArray } from '../service/utils'

function Canais() {
    const [canais, setCanais] = useState([])
    const [canalAtual, setCanalAtual] = useState(null)
    const [loading, setLoading] = useState(true)
    const [mostrarEventos, setMostrarEventos] = useState(null)
    const [row, setRow] = useState("group-0")

    const changeEvent = (canal, row) => {
        setCanalAtual(canal)
        setRow(row)
        setMostrarEventos(null)
        setTimeout(() => {
            let element = document.querySelector("#detalhe-evento");
            element.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }, 300);
    }

    useEffect(() => {
        api.retornarCanaisWEBAoVivo()
            .then(res => {
                if (res.success) {
                    setCanais(splitArray(res.canais, 7))
                    const canal = res.canais[0]
                    setCanalAtual(canal)
                    setLoading(false)
                }
            })
    }, [])

    return <div className="flex flex-col">
        <div id="detalhe-evento" className="flex flex-col">
            <div className="absolute flex flex-col lg:py-20 w-full lg:w-1/2">
                {loading
                    ?
                    <div className="w-full mx-auto pl-20 pt-20">
                        <div className="animate-pulse flex space-x-4">
                            <div className="flex-1 space-y-4 py-1">
                                <div className="h-4 bg-gray-400 bg-opacity-25 rounded w-1/6"></div>
                                <div className="h-4 bg-gray-400 bg-opacity-25 rounded w-1/5"></div>
                                <div className="space-y-2">
                                    <div className="h-4 bg-gray-400 bg-opacity-25 rounded w-3/5"></div>
                                    <div className="h-4 bg-gray-400 bg-opacity-25 rounded w-3/4"></div>
                                    <div className="h-4 bg-gray-400 bg-opacity-25 rounded w-2/5"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="flex flex-col pl-20 pt-20">
                        <span className="text-lg">{canalAtual?.titulo}</span>
                        <span className="text-3xl mt-2 truncate">{canalAtual?.evento?.titulo}</span>
                        <div className="flex items-center space-x-2">
                            <span className="text-sm">{canalAtual?.evento?.inicio_formatado} - {canalAtual?.evento?.fim_formatado}</span>
                            <img className="w-5" src={canalAtual?.evento?.classificacao} />
                        </div>
                        <span className="py-1">Categorias: {canalAtual?.evento?.categorias}</span>
                        <span className="text-lg">{canalAtual?.evento?.subtitulo}</span>
                        <div className="mt-2 mr-5 h-20 xl:h-full scrollable">
                            <span className="font-light text-lg">{canalAtual?.evento?.descricao}</span>
                        </div>
                        <div className="mt-2 py-2">
                            <Link to={`/app/watch/${canalAtual?.conteudo_uuid}`} className="bg-gray-600 hover:bg-white hover:text-black p-2 rounded font-semibold"><i className="fa fa-play-circle mr-1" />Assistir agora</Link>
                            <button onClick={() => { setMostrarEventos(row) }} className="bg-gray-600 hover:bg-white hover:text-black p-2 rounded font-semibold ml-2"><i className="fa fa-table mr-1" />Programação</button>
                        </div>
                    </div>
                }
            </div>
            <div className="image-container" >
                <img onError={e => { e.target.error = null; e.target.src = canalAtual.icone }} src={canalAtual?.evento?.imagem} />
            </div>
        </div>
        {canais.length > 0 &&
            <div className="px-10">
                <div className="flex flex-row items-center lg:justify-start pl-10 my-5">
                    <span className="text-2xl font-light">Ao Vivo</span>
                </div>
                {canais.map((group, gIndex) => {
                    const row = `group-${gIndex}`
                    return (
                        <div key={row}>
                            <div className="flex flex-row">
                                {group.map((canal, index) => (
                                    <div id="epg_image_container" key={`item-${index}`} className="m-1 item">
                                        <div onClick={() => { changeEvent(canal, row) }} className="bg-gray-800 rounded cursor-pointer">
                                            <img id="channel_icon" src={canal.icone} alt={canal.titulo} />
                                            <div id="epg_image" className="absolute top-0 w-full h-full">
                                                <div className="absolute w-full h-full flex flex-col justify-center p-2 bg-gray-700 bg-opacity-25">
                                                    <i className="fa fa-play-circle text-5xl self-center"></i>
                                                    <div className="flex">
                                                        <span className="truncate ">{canal?.evento?.titulo}</span>
                                                        <img className="w-5 ml-1" src={canal?.evento?.classificacao} />
                                                    </div>
                                                </div>
                                                <img onError={e => { e.target.error = null; e.target.style.display = 'none' }} src={canal?.evento?.imagem} alt={canal.evento.titulo} />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {mostrarEventos == row && <Programacoes id={`programacoes-${row}`} canalAtual={canalAtual} close={() => setMostrarEventos(false)} />}
                        </div>
                    )
                })}

            </div>
        }
    </div>
}

export default Canais;