import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as api from '../service/api';

const logoUrl = process.env.PUBLIC_URL + '/imagens/logo.svg';

function Account() {
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const [dadosUsuario, setDadosUsuario] = useState(null)

    const border = <hr className="border-orange-700 mt-3" />

    useEffect(() => {
        api.infoUsuario()
            .then(res => {
                if (!res.success) {
                    if (res.code == 0) {
                        history.replace('/logout')
                    }
                    return
                }

                let dados = res.dados
                let pacotes = JSON.parse(dados.pacotes)

                dados.pacotes = pacotes.map((pacote) => pacote.descricao).join(', ')
                setDadosUsuario(dados)

                let pacoteOTT = pacotes.filter(pacote => pacote.ott == true)
                if (pacoteOTT.length > 0) {
                    // console.log(pages)
                    // setPages([{ path: 'ativar', title: 'Ativar um dispositivo' }, { path: 'watch', title: ' Assistir Jogo do cavalo de Aço ao vivo' }])
                }
            })
    }, [])

    return (
        <div className="container mx-auto p-2 pt-10 text-xl">
            <span className="text-3xl font-bold">Conta</span>

            {border}

            <div className="flex flex-col lg:flex-row px-5">
                <div className="pt-3 lg:w-1/3">
                    <span className="text-xl">Informações do usuário</span>
                </div>
                <div className="pt-3 flex flex-col">
                    <span>Usuário: <strong>{dadosUsuario?.usuario}</strong></span>
                    {/* <div>
								<span>Email: <strong>{dadosUsuario?.email}</strong></span>
								<Link style={{ float: "right" }} to="/email">Alterar email</Link>
							</div> */}
                    <Link className="text-blue-500" to="/app/password">Alterar senha</Link>
                </div>
            </div>

            {border}

            <div className="flex flex-col lg:flex-row px-5">
                <div className="pt-3 lg:w-1/3">
                    <span className="text-xl">Pacotes contratados</span>
                </div>
                <div className="pt-3">
                    <strong>{dadosUsuario?.pacotes}</strong>
                    {/* <Link style={{float: "right"}} to="/plano">Alterar plano</Link> */}
                </div>
            </div>

            {border}

            <div className="flex flex-col lg:flex-row px-5">
                <div className="pt-3 lg:w-1/3">
                    <span className="text-xl">Configurações</span>
                </div>
                <ul className="mt-3 space-y-3">
                    <li><Link className="text-blue-500" to='/app/device_manager'>Meus dispositivos</Link></li>
                    <li><Link className="text-blue-500" to='/app/ativar' >Ativar um dispositivo</Link></li>
                    <li><Link className="text-blue-500" to="/app/user_access">Atividade recente na conta</Link></li>
                    <li><Link className="text-blue-500" to="/app/logout_all">Encerrar sessão em todos os dispositivos</Link></li>
                </ul>
            </div>

            <div className=" my-5 p-5 bg-gray-800 rounded flex flex-col">
                <span >Para assistir aos canais no seu celular baixe o </span>
                <a className="text-blue-500 font-black" href="https://play.google.com/store/apps/details?id=br.com.jupiter.jupitertv">App do Júpiter TV</a>
            </div>
        </div >
    )
}

export default Account;