import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import TVGuide from './components/epg/components/TVGuide';
import Header from './components/header';
import ListaCanais from './views/ListaCanais';
import Watch from './views/Watch';
import Account from './views/Account';
import Pass from './views/Pass';
import DeviceManager from './views/DeviceManager';
import Ativar from './views/Ativar';
import UserAccess from './views/UserAccess';
import LogoutAll from './views/LogoutAll';
import DetalheEvento from './views/DetalheEvento';
import Media from 'react-media'
import WatchVOD from './views/WatchVOD';

function App() {
  return (
    <>
      <Header />

      <Media query="(max-width: 599px)" render={() =>
      (
        <Switch>
          <Route path="/app/account" exact component={Account} />
          <Route path="/app/password" component={Pass} />
          <Route path="/app/ativar" component={Ativar} />
          <Route path="/app/device_manager" component={DeviceManager} />
          <Route path="/app/user_access" component={UserAccess} />
          <Route path="/app/logout_all" component={LogoutAll} />
          <Redirect to="/app/account" />
        </Switch>
      )} />

      <Media query="(min-width: 600px)" render={() =>
      (
        <Switch>
          <Route path="/app" exact component={ListaCanais} />
          <Route path="/app/detalhe/:epg_uuid" component={DetalheEvento} />
          <Route path="/app/watch-vod/:uuid" component={WatchVOD} />
          <Route path="/app/watch/:conteudo_uuid" component={Watch} />
          <Route path="/app/guide" component={TVGuide} />
          <Route path="/app/account" component={Account} />
          <Route path="/app/ativar" component={Ativar} />
          <Route path="/app/password" component={Pass} />
          <Route path="/app/device_manager" component={DeviceManager} />
          <Route path="/app/user_access" component={UserAccess} />
          <Route path="/app/logout_all" component={LogoutAll} />
        </Switch>
      )} />

    </>
  )
}

export default App;