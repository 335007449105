import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as auth from '../service/auth'

function Logout() {
    const history = useHistory()
    useEffect(() => {
        auth.logout()
        history.push('/')
    }, [history])
    return <div />
}

export default Logout;