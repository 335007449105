import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as api from '../../service/api';
import { tiposEnvios } from '../../service/constants';

function FormUsuario() {

    const history = useHistory()

    const [tipoEnvio, setTipoEnvio] = useState('email')
    const [usuario, setUsuario] = useState('')
    const [erroUsuario, setErroUsuario] = useState(null)
    const [feedback, setFeedback] = useState(null)

    const border = <hr className="border-orange-700 mb-2" />

    const voltar = () => {
        setUsuario('')
        setErroUsuario('')
    }

    const getFeedback = (tipo, msg) => {
        switch (tipo) {
            case 'success':
                return (
                    <div className="bg-green-500 bg-opacity-50 rounded relative p-2">
                        <button className="absolute top-0 right-0 m-1" type="button" onClick={() => { setFeedback(null) }}>
                            <span>&times;</span>
                        </button>
                        <div>{msg}</div>
                    </div>
                )
            case 'error':
                return (
                    <div className="bg-red-600 bg-opacity-50 rounded relative p-2">
                        <button className="absolute top-0 right-0 m-1" type="button" onClick={() => { setFeedback(null) }}>
                            <span>&times;</span>
                        </button>
                        <div>{msg}</div>
                    </div>
                )
        }
    }

    const verificarUsuario = () => {
        if (usuario.length < 3) {
            setErroUsuario('O campo usuário/email deve ser preenchido corretamente')
            return
        }

        api.verificarUsuario({ usuario: usuario, tipoEnvio: tipoEnvio })
            .then(res => {
                if (!res.success) {
                    setFeedback(getFeedback('error', res.message))
                    return
                }

                setFeedback(null)
                history.replace('/recover_pass/code')
            })
    }

    const getTipoEnvio = (tipoEnvio) => {
        return tiposEnvios[tipoEnvio]
    }

    return (
        <div className="w-3/4 lg:w-1/4 mx-auto flex flex-col items-center justify-center mt-20">

            <img src={process.env.PUBLIC_URL + './imagens/logo_jupitertv.svg'} className="col-center-block mb-4" alt="logo" width="200px" />

            <div className='w-full'>
                <span className="text-2xl">Redefinir Senha</span>

                {border}

                {feedback}

                <div className="row account-section">
                    <div className="flex flex-col">
                        <label className="text-muted">Usuário ou Email</label>
                        <input autoFocus className="p-2 rounded text-black" required type="text" name="usuario" minLength='6' autoCapitalize="none" value={usuario} autoComplete="off" onChange={(e) => setUsuario(e.target.value)} />
                        <div className="text-red-500 mt-1"> {erroUsuario} </div>
                    </div>
                    <div className="pt-3">
                        <span>Escolha como quer redefinir sua senha</span>
                    </div>

                    <div className='flex items-center space-x-1 mt-2'>
                        <input className='cursor-pointer w-4 h-4' type="radio" name='email' value="email" checked={tipoEnvio === 'email'} onChange={() => setTipoEnvio('email')} />
                        <label className='font-semibold cursor-pointer' htmlFor='email' onClick={() => setTipoEnvio('email')}>
                            Enviar por E-mail
                        </label>
                    </div>

                    <div className='flex items-center space-x-1 mt-2'>
                        <input className='cursor-pointer w-4 h-4' type="radio" name='sms' value="sms" checked={tipoEnvio === 'sms'} onChange={() => setTipoEnvio('sms')} />
                        <label className='font-semibold cursor-pointer' htmlFor='sms' onClick={() => setTipoEnvio('sms')}>
                            Enviar por SMS
                        </label>
                    </div>

                    <div className='flex items-center space-x-1 mt-2'>
                        <input className='cursor-pointer w-4 h-4' type="radio" name='whatsapp' value="whatsapp" checked={tipoEnvio === 'whatsapp'} onChange={() => setTipoEnvio('whatsapp')} />
                        <label className='font-semibold cursor-pointer' htmlFor='whatsapp' onClick={() => setTipoEnvio('whatsapp')}>
                            Enviar por WhatsApp
                        </label>
                    </div>

                    <div className="mt-5 flex items-center justify-between">
                        <Link to="/"><button type="button" className="bg-gray-600 hover:bg-gray-700 p-2 rounded mr-2">Cancelar</button></Link>
                        <button onClick={() => verificarUsuario()} type="submit" className="bg-blue-600 hover:bg-blue-700 p-2 rounded">Enviar por {getTipoEnvio(tipoEnvio)}</button>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default FormUsuario;