import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as api from '../service/api'

function ProgramacoesVOD({ canal, close }) {
    const [videos, setVideos] = useState([])

    useEffect(() => {
        api.retornarUltimosVideosDoCanalVOD(canal?.conteudo_uuid)
            .then(res => {
                if (res.success) {
                    setVideos(res.videos)
                    let element = document.querySelector(`#programacoes-${canal.conteudo_uuid}`);
                    element.scrollIntoView({ behavior: 'smooth', block: 'end' });
                }
            })
    }, [canal])

    return (
        <div id={`programacoes-${canal.conteudo_uuid}`} className="bg-gray-900 bg-opacity-75 flex flex-col px-5">
            <div className="flex items-center justify-between">
                <span className="text-2xl font-bold py-2">Últimos vídeos</span>
                <button onClick={() => close()} className="text-2xl m-4"><i className="fa fa-close"></i></button>
            </div>
            <div className="w-full flex flex-col lg:flex-row overflow-x-auto">
                {videos.map(video => (
                    <div key={video.uuid} className="w-full flex flex-col p-2 justify-start">
                        <img style={{ objectFit: 'contain' }} src={video.capa} />
                        <Link to={`/app/watch-vod/${video.uuid}`} className="text-lg mt-2">{video.titulo}</Link>
                        <div className="mt-2 mr-5 h-20 scrollable">
                            <span className="font-light text-sm">{video.descricao}</span>
                        </div>
                    </div>
                ))}

                {/* <Link to="/app/watch-vod" className="text-lg m-2 w-1/2 flex items-center justify-center">
                    <div className='flex flex-col items-center hover:bg-gray-700 p-5 rounded-sm'>
                        <i className="fa fa-arrow-right"></i>
                        <span>Ver todos</span>
                    </div>
                </Link> */}
            </div>
        </div >
    )
}

export default ProgramacoesVOD;