import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './Routes';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './index.css'
import './styles/styles.scss'

ReactDOM.render(
  <Routes />,
  document.getElementById('root')
);
