import React, { useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as api from '../../service/api';

function CodigoDeRedefinicao() {

    const history = useHistory()

    const senhaRef = useRef()
    const confirmacaoSenhaRef = useRef()

    const [verSenha, setVerSenha] = useState(false)
    const [verConfirmacaoSenha, setVerConfirmacaoSenha] = useState(false)

    const [codigoVerificado, setCodigoVerificado] = useState(false)
    const [token, setToken] = useState(null)

    const [senha, setSenha] = useState("")
    const [erroSenha, setErroSenha] = useState(null)
    const [confirmacaoSenha, setConfirmacaoSenha] = useState("")
    const [erroConfirmacaoSenha, setErroConfirmacaoSenha] = useState(null)

    const [codigo, setCodigo] = useState('')
    const [erroCodigo, setErroCodigo] = useState(null)

    const [feedback, setFeedback] = useState(null)

    const border = <hr className="border-orange-700 mb-2" />

    const voltar = () => {
        history.replace('/recover_pass')
    }

    const getFeedback = (tipo, msg) => {
        switch (tipo) {
            case 'success':
                return (
                    <div className="bg-green-500 bg-opacity-50 rounded relative p-2">
                        <button className="absolute top-0 right-0 m-1" type="button" onClick={() => { setFeedback(null) }}>
                            <span>&times;</span>
                        </button>
                        <div>{msg}</div>
                    </div>
                )
            case 'error':
                return (
                    <div className="bg-red-600 bg-opacity-50 rounded relative p-2">
                        <button className="absolute top-0 right-0 m-1" type="button" onClick={() => { setFeedback(null) }}>
                            <span>&times;</span>
                        </button>
                        <div>{msg}</div>
                    </div>
                )
        }
    }

    const verificarCodigo = () => {
        if (codigo.length < 6) {
            setErroCodigo('O campo código deve ser preenchido corretamente')
            return
        }

        api.verificarCodigo({ codigo })
            .then(res => {
                if (!res.success) {
                    setFeedback(getFeedback('error', res.message))
                    return
                }

                setToken(res.token)
                setCodigo('')
                setCodigoVerificado(true)
            })
    }

    const redefinirSenha = () => {
        if (senha.length < 6) {
            setErroSenha('A senha código deve ter no mínimo 6 caracteres')
            return
        }

        api.redefinirSenha({ senha, token })
            .then(res => {
                if (!res.success) {
                    setFeedback(getFeedback('error', res.message))
                    return
                }

                setFeedback(getFeedback('success', 'Sua senha foi redefinida. Você será redirecionado para a página de login'))

                setTimeout(() => {
                    history.replace('/')
                }, 4000);
            })
    }

    const alternarVisibilidadeDoCampoSenha = () => {
        setVerSenha(!verSenha)
        senhaRef.current.focus()
    }

    const alternarVisibilidadeDoCampoConfirmacao = () => {
        setVerConfirmacaoSenha(!verConfirmacaoSenha)
        confirmacaoSenhaRef.current.focus()
    }

    const camposEstaoValidos = () => {
        return senha && confirmacaoSenha && senha === confirmacaoSenha
    }

    const codigoRender = () => (
        <div className="w-full">
            <span className="text-2xl">Informe o código de validação</span>
            {border}

            {feedback}
            <div className="row">
                <div className="flex flex-col">
                    <label className="text-muted">Código</label>
                    <input required type="text" autoFocus name="codigo" minLength="6" autoCapitalize="none" value={codigo} className="p-2 rounded text-black" autoComplete="off" onChange={(e) => setCodigo(e.target.value)} />
                    <div className="text-red-500">
                        {erroCodigo}
                    </div>
                </div>
                <div className="mt-5">
                    <button type="button" className="bg-gray-600 hover:bg-gray-700 p-2 rounded mr-2" onClick={voltar} >Cancelar</button>
                    <button type="submit" className="bg-blue-600 hover:bg-blue-700 p-2 rounded" onClick={() => verificarCodigo()}>Validar código</button>
                </div>
            </div>
        </div>
    )

    const formRedefinicao = () => (
        <div className='w-full'>
            <span className="text-2xl">Redefinir Senha</span>

            {border}

            {feedback}

            <div className="row account-section">

                <div className="flex flex-col mt-5 ">
                    <label className="text-muted">Senha</label>
                    <div className='flex items-center relative w-full'>
                        <input ref={senhaRef} autoFocus className="p-2 rounded text-black w-full" required type={verSenha ? "text" : 'password'} name="senha" minLength='6' autoCapitalize="none" value={senha} autoComplete="off" onChange={(e) => setSenha(e.target.value)} />
                        <i onClick={() => alternarVisibilidadeDoCampoSenha()} className={`absolute right-2 text-lg cursor-pointer text-gray-600 ${verSenha ? 'fa fa-eye-slash' : 'fa fa-eye'} `}></i>
                    </div>
                    <div className="text-red-500 mt-5"> {erroSenha} </div>

                </div>

                <div className="flex flex-col mt-5">
                    <label className="text-muted">Confirmar senha</label>
                    <div className='flex items-center relative w-full'>
                        <input ref={confirmacaoSenhaRef} className="p-2 rounded text-black w-full" required type={verConfirmacaoSenha ? "text" : 'password'} name="confirmar-senha" minLength='6' autoCapitalize="none" value={confirmacaoSenha} autoComplete="off" onChange={(e) => setConfirmacaoSenha(e.target.value)} />
                        <i onClick={() => alternarVisibilidadeDoCampoConfirmacao()} className={`absolute right-2 text-lg cursor-pointer text-gray-600 ${verConfirmacaoSenha ? 'fa fa-eye-slash' : 'fa fa-eye'} `}></i>
                    </div>
                    <div className="text-red-500 mt-5"> {erroConfirmacaoSenha} </div>
                </div>

                <div className="mt-5 flex items-center justify-between">
                    <Link to="/"><button type="button" className="bg-gray-600 hover:bg-gray-700 p-2 rounded mr-2">Cancelar</button></Link>
                    <button disabled={!camposEstaoValidos()} onClick={() => redefinirSenha()} type="submit" className="bg-blue-600 hover:bg-blue-700 disabled:bg-blue-300 p-2 rounded">Redefinir senha</button>
                </div>

            </div>

        </div>
    )

    return (
        <div className="w-full mx-auto flex items-center justify-center">
            <div className="mt-20 flex flex-col items-center w-3/4 lg:w-1/4">
                <img src={`${process.env.PUBLIC_URL}/imagens/logo_jupitertv.svg`} className="col-center-block mb-4" alt="logo" width="200px" />
                {codigoVerificado ? formRedefinicao() : codigoRender()}
            </div>
        </div>
    )
}

export default CodigoDeRedefinicao;